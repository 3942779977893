import RowEditable from "components/RowEditable";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function StockTable({ list, maj, setMaj }) {
  const handleEdit = (edited, id) => {
    var data = {
      id: id,
      Compras: edited[2].value,
      Comentario: edited[6].value,
    };
    console.log(data);
    var url = "/api/stocks";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(~maj);
  };

  return (
    <>
      {/* Table items */}
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Fecha
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Alimentacion
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Compras
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Ventas
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Diff de stock
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Stock
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Comentario
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(
            (
              {
                id,
                Fecha,
                Alimentacion,
                Compras,
                Ventas,
                Diff,
                Stock,
                Comentario,
              },
              key
            ) => {
              const row = [
                {
                  name: "Fecha",
                  value: Fecha,
                  editable: false,
                },
                {
                  name: "Alimentacion",
                  value: Alimentacion,
                  editable: false,
                },
                {
                  name: "Compras",
                  value: Compras,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "Ventas",
                  value: Ventas,
                  editable: false,
                },
                {
                  name: "Diff",
                  value: Diff,
                  editable: false,
                },
                {
                  name: "Stock",
                  value: Stock,
                  editable: false,
                },
                {
                  name: "Comentario",
                  value: Comentario,
                  editable: true,
                  type: "input-text",
                },
              ];
              return (
                <RowEditable
                  key={id}
                  id={id}
                  row={row}
                  color={key % 2 === 1 ? "teal-50" : "white"}
                  handleEdit={handleEdit}
                  removeDelete={true}
                />
              );
            }
          )}
        </tbody>
      </table>
    </>
  );
}
