import React from "react";

export default function Total({ total, discount, tax }) {
  return (
    <div>
      <h3 className="flex items-end justify-end text-gray-800 text-4xl font-bold">
        Total :{total}
      </h3>
    </div>
  );
}
