import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import RowEditable from "components/RowEditable";
import ChangeAliment from "components/Factures/ChangeAliment";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";

export default function Article({ list, setList, itemsList, setMaj, maj }) {
  const [newRow, setNewRow] = useState({
    item_id: itemsList[0][0],
    item_name: itemsList[0][1],
    item_weight_per_unity: itemsList[0][2],
    item_price_per_unity: itemsList[0][3],
    quantity: "",
    price: itemsList[0][3],
    discount: 0,
  });
  const [quantityType, setQuantityType] = useState(false);
  const [open, setOpen] = useState(false);

  // Delete function
  const deleteRow = (id) => setList(list.filter((row) => row.id !== id));

  const handleItemChange = (id) => {
    const it = itemsList.filter((i) => i[0] === parseInt(id))[0];
    // check for default price
    setNewRow({
      ...newRow,
      item_id: it[0],
      item_name: it[1],
      item_weight_per_unity: it[2],
      item_price_per_unity: it[3],
      price: it[3] > 0 ? parseFloat(it[3]) : "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const it = itemsList.filter((i) => i[0] === parseInt(newRow.item_id))[0];

    if (!newRow.quantity || !newRow.price) {
      toast.warn("Llenar todas las informaciones!");
    } else {
      const newItems = {
        id: uuidv4(),
        item_id: newRow.item_id,
        item_name: newRow.item_name,
        quantity: quantityType
          ? parseInt((newRow.quantity / it[2]) * 1000) / 1000
          : newRow.quantity,
        weight: quantityType
          ? newRow.quantity
          : parseInt(newRow.quantity * it[2] * 1000) / 1000,
        price: quantityType ? newRow.price * it[2] : newRow.price,
        weightPrice: quantityType ? newRow.price : newRow.price / it[2],
        discount: newRow.discount,
      };
      setNewRow({
        item_id: itemsList[0][0],
        item_name: itemsList[0][1],
        item_weight_per_unity: itemsList[0][2],
        item_price_per_unity: itemsList[0][3],
        quantity: "",
        price: itemsList[0][3],
        discount: 0,
      });
      setList([...list, newItems]);
    }
  };

  const handleNewRow = (row, id) => {
    const i = list.findIndex((r) => r.id === id);
    const it = itemsList.filter((i) => i[0] === parseInt(row[0].value_id))[0];
    var temp = [...list];
    temp[i] = {
      id: id,
      item_id: row[0].value_id,
      item_name: row[0].value,
      quantity: row[1].value,
      weight: row[1].value * it[2],
      price: row[3].value,
      weightPrice: row[3].value / it[2],
      discount: row[4].value,
    };
    setList(temp);
  };

  return (
    <>
      {/* Table items */}

      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Item
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Cantidad sacos
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Peso
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Precio por saco
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Descuento
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Total
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(
            (
              { id, item_id, item_name, quantity, weight, discount, price },
              key
            ) => {
              const row = [
                {
                  name: "item_name",
                  value: item_name,
                  value_id: item_id,
                  editable: true,
                  type: "select",
                  selectList: itemsList,
                },
                {
                  name: "quantity",
                  value: quantity,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "weight",
                  value: weight,
                  editable: false,
                },
                {
                  name: "price",
                  value: price,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "discount",
                  value: discount,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "Total",
                  value: parseInt(quantity * price * (1 - discount / 100)),
                  editable: false,
                },
              ];
              return (
                <RowEditable
                  id={id}
                  key={key}
                  row={row}
                  color={key % 2 === 1 ? "teal-50" : "white"}
                  handleEdit={handleNewRow}
                  deleteRow={deleteRow}
                />
              );
            }
          )}
        </tbody>
      </table>
      <ChangeAliment
        item={itemsList.filter((i) => i[0] === parseInt(newRow.item_id))[0]}
        maj={maj}
        setMaj={setMaj}
        open={open}
        setOpen={setOpen}
      />
      <form className="mt-5" onSubmit={handleSubmit} id="addRow">
        <div className="md:grid grid-cols-6 gap-6">
          <div className="flex flex-col">
            <section className="flex flex-row " onClick={() => setOpen(true)}>
              <label className="hover:text-teal-700">Item</label>
              <AiOutlineEdit className="text-green-600 h-5 " />
            </section>
            <select
              value={newRow.item_id}
              onChange={(e) => handleItemChange(e.target.value)}
            >
              {itemsList.map((i, key) => (
                <option key={key} value={i[0]}>
                  {i[1] + " | " + i[2] + "lbs por sacos"}
                </option>
              ))}
            </select>
          </div>
          <div className="flex flex-col">
            <label
              className="hover:text-teal-700"
              htmlFor="quantity"
              onClick={() => setQuantityType(~quantityType)}
            >
              Cantidad {quantityType ? "lbs" : "sacos"}
            </label>
            <input
              type="number"
              name="quantity"
              id="quantity"
              placeholder="Cantidad"
              value={newRow.quantity}
              onChange={(e) =>
                setNewRow({ ...newRow, quantity: e.target.value })
              }
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="price">
              Precio {quantityType ? "lbs" : "sacos"}
            </label>
            <input
              type="number"
              name="price"
              id="price"
              placeholder="Precio"
              value={newRow.price}
              onChange={(e) => setNewRow({ ...newRow, price: e.target.value })}
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="discount">Descuento %</label>
            <input
              type="number"
              name="discount"
              id="discount"
              placeholder="Descuento"
              value={newRow.discount}
              onChange={(e) =>
                setNewRow({ ...newRow, discount: e.target.value })
              }
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="amount">Total</label>
            <p className="p-2 border-2 rounded bg-teal-100 border-teal-100">
              {quantityType
                ? parseInt(
                    newRow.quantity * newRow.price * (1 - newRow.discount / 100)
                  )
                : parseInt(
                    newRow.quantity * newRow.price * (1 - newRow.discount / 100)
                  )}
            </p>
          </div>
          <div className="flex flex-col justify-center mt-2">
            <button type="submit" className="btn-teal h-12 mb-5">
              Añadir item
            </button>
          </div>
        </div>
      </form>
    </>
  );
}
