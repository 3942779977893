import { useState } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function AddUser({ majTop, setMajTop, roleList, roleTrad }) {
  const [newUser, setNewUser] = useState({
    name: "",
    password: "",
    passwordConfirm: "",
    role: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/user";
    if (newUser.password < 5) {
      toast.warn("Contraseña demasiado corta");
    } else if (newUser.password !== newUser.passwordConfirm) {
      toast.warn("Las contraseñas no coinciden");
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: newUser.name,
          password: newUser.password,
          role: newUser.role,
        }),
      };
      authFetch(url, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((data) => {
              throw new Error(data.message);
            });
          }
        })
        .then((response) => {
          toast.success(response.message);
          setMajTop(~majTop);
          setNewUser({ name: "", password: "", passwordConfirm: "", role: "" });
        })
        .catch((e) => toast.error(e.name + ": " + e.message));
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-5 m-2">
      <h2 className="border-b-2 ">Añadir usuario</h2>
      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="md:grid grid-cols-2 gap-2">
          <section className="flex flex-col">
            <label>Nombre</label>
            <input
              type="text"
              name="name"
              value={newUser.name}
              onChange={(e) =>
                setNewUser((prev) => {
                  return { ...prev, name: e.target.value };
                })
              }
              placeholder="Nombre"
            />
          </section>
          <section className="flex flex-col">
            <label>Contraseña</label>
            <input
              type="password"
              name="password"
              value={newUser.password}
              onChange={(e) =>
                setNewUser((prev) => {
                  return { ...prev, password: e.target.value };
                })
              }
              placeholder="Contraseña"
            />
          </section>
          <section className="flex flex-col">
            <label>Contraseña</label>
            <input
              type="password"
              name="passwordConfirm"
              value={newUser.passwordConfirm}
              onChange={(e) =>
                setNewUser((prev) => {
                  return { ...prev, passwordConfirm: e.target.value };
                })
              }
              placeholder="Confirmar contraseña"
            />
          </section>
          <section className="flex flex-col">
            <label>Función</label>
            <select
              name="role"
              value={newUser.role}
              onChange={(e) =>
                setNewUser((prev) => {
                  return { ...prev, role: e.target.value };
                })
              }
              placeholder="Función"
            >
              {roleList.map((r, key) => {
                return (
                  <option key={key} value={r[0]}>
                    {roleTrad[r[1]]}
                  </option>
                );
              })}
            </select>
          </section>
          <button
            type="submit"
            className="h-12 self-center mb-5 bg-teal-700 text-white font-bold py-2 px-8 rounded shadow border-2 border-teal-700 hover:bg-teal-600 transition-all duration-300"
          >
            Añadir usuario
          </button>
        </div>
      </form>
    </div>
  );
}
