import React, { useState } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function FormDialog({ espece, lot, setMaj, maj }) {
  const [open, setOpen] = useState(false);
  const [semis, setSemis] = useState({
    date: "",
    poids: "",
    quantitelb: "",
    grpoisson: "",
    commentaire: "",
    quantite: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let quantite = 0;
    let poids = 0;
    console.log(espece, semis);
    if (espece.substring(0, 10) === "Postlarves") {
      quantite = parseFloat(semis.quantite);
      poids = parseFloat(semis.quantite) / parseFloat(semis.quantitelb);
    } else if (espece.substring(0, 9) === "Juvéniles") {
      quantite = parseFloat(semis.quantitelb) * parseFloat(semis.poids);
      poids = parseFloat(semis.poids);
    } else if (semis.poids === "") {
      quantite = parseFloat(semis.quantite);
      poids = (parseFloat(semis.quantite) * parseFloat(semis.grpoisson)) / 454;
    } else if (semis.grpoisson === "") {
      quantite = parseFloat(semis.quantite);
      poids = parseFloat(semis.poids);
    }
    var url = "/api/semis";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...semis,
        date: semis.date,
        quantite: quantite,
        poids: poids,
        lot: lot,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setOpen(false);
    setSemis({
      date: "",
      typePoids: "",
      poids: "",
      grpoisson: "",
      quantitelb: "",
      commentaire: "",
    });
  };

  return (
    <div>
      <button className="btn-teal" type="button" onClick={() => setOpen(true)}>
        Añadir siembra
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2>Añadir una siembra</h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <form className="mt-5" onSubmit={handleSubmit}>
                  <div className="md:grid grid-cols-3 gap-2 relative p-6">
                    <section className="flex flex-col">
                      <label>Fecha</label>
                      <input
                        required
                        className="m-0"
                        type="date"
                        placeholder="Fecha"
                        value={semis.date}
                        onChange={(e) =>
                          setSemis({ ...semis, date: e.target.value })
                        }
                      />
                    </section>
                    {espece &&
                    (espece.substring(0, 10) === "Postlarves" ||
                      espece.substring(0, 5) === "Panga" ||
                      espece.substring(0, 7) === "Tilapia" ||
                      espece.substring(espece.length - 7, espece.length) ===
                        "tilapia" ||
                      espece.substring(0, 6) === "Carpes" ||
                      espece.substring(0, 9) === "Colossoma") ? (
                      <section className="flex flex-col">
                        <label>Cantidad total</label>
                        <input
                          type="text"
                          value={semis.quantite}
                          onChange={(e) =>
                            setSemis({
                              ...semis,
                              quantite: e.target.value,
                            })
                          }
                        />
                      </section>
                    ) : (
                      <section className="flex flex-col">
                        <label>Peso total</label>
                        <input
                          type="text"
                          value={semis.poids}
                          onChange={(e) =>
                            setSemis({
                              ...semis,
                              poids: e.target.value,
                            })
                          }
                        />
                      </section>
                    )}
                    {espece &&
                    (espece.substring(0, 7) === "Tilapia" ||
                      espece.substring(0, 5) === "Panga" ||
                      espece.substring(espece.length - 7, espece.length) ===
                        "tilapia" ||
                      espece.substring(0, 5) === "Carpe" ||
                      espece.substring(0, 9) === "Colossoma") ? (
                      <section className="flex flex-col">
                        <label>Gr par poissons</label>
                        <input
                          type="text"
                          value={semis.grpoisson}
                          onChange={(e) =>
                            setSemis({
                              ...semis,
                              grpoisson: e.target.value,
                            })
                          }
                        />
                      </section>
                    ) : (
                      <section className="flex flex-col">
                        <label>Cantitad por libras</label>
                        <input
                          type="text"
                          value={semis.quantitelb}
                          onChange={(e) =>
                            setSemis({
                              ...semis,
                              quantitelb: e.target.value,
                            })
                          }
                        />
                      </section>
                    )}
                    {espece &&
                    (espece.substring(0, 7) === "Tilapia" ||
                      espece.substring(0, 5) === "Panga" ||
                      espece.substring(espece.length - 7, espece.length) ===
                        "tilapia" ||
                      espece.substring(0, 5) === "Carpe" ||
                      espece.substring(0, 9) === "Colossoma") ? (
                      <section className="flex flex-col">
                        <label>Peso total</label>
                        <input
                          type="text"
                          value={semis.poids}
                          onChange={(e) =>
                            setSemis({
                              ...semis,
                              poids: e.target.value,
                            })
                          }
                        />
                      </section>
                    ) : null}
                    <section className="flex flex-col">
                      <label>Comentario</label>
                      <input
                        type="text"
                        value={semis.commentaire}
                        onChange={(e) =>
                          setSemis({ ...semis, commentaire: e.target.value })
                        }
                      />
                    </section>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="btn-red-transparent"
                      type="button"
                      onClick={() => setOpen(false)}
                    >
                      Cerrar
                    </button>
                    <button className="btn-teal" type="submit">
                      Confirmar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
