import { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function ShowTotal() {
  const [tableData, setTableData] = useState("");
  const [dates, setDates] = useState({ first: "", second: "" });

  useEffect(() => {
    var url = "/api/totalPompes/" + dates.first + "/" + dates.second;
    if (dates.first.length === 10 && dates.second.length === 10) {
      authFetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((data) => {
              throw new Error(data.message);
            });
          }
        })
        .then((response) => {
          setTableData(response.total);
        })
        .catch((e) => toast.error(e.name + ": " + e.message));
    }
  }, [dates]);

  return (
    <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
      <div className="flex justify-between border-b-2 mb-4">
        <h2 className="my-5">Total recambio</h2>
        <div className="flex flex-row">
          <section className="flex flex-col mt-2 justify-center mx-2">
            <label>Fecha</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha"
              value={dates.first}
              onChange={(e) =>
                setDates({
                  ...dates,
                  first: e.target.value,
                })
              }
            />
          </section>
          <section className="flex flex-col mt-2 justify-center">
            <label>Fecha</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha"
              value={dates.second}
              onChange={(e) =>
                setDates({
                  ...dates,
                  second: e.target.value,
                })
              }
            />
          </section>
        </div>
      </div>
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Bomba
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Total en horas
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData
            ? tableData.map((r, key) => {
                console.log(r);
                return (
                  <tr
                    key={key}
                    className={
                      key % 2 === 0
                        ? "bg-white border-b hover:bg-gray-100"
                        : "bg-teal-50 border-b hover:bg-gray-100"
                    }
                  >
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      {r.Pompe}
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      {r["Heures"]}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </div>
  );
}
