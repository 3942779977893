import { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

const getLastMonth = (date = new Date()) => {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 30);

  return previous;
};

export default function ShowTotalAeration({ majMaster, bassinsList }) {
  const [tableData, setTableData] = useState("");
  const [dates, setDates] = useState({
    first: getLastMonth().toISOString().substring(0, 10),
    second: new Date().toISOString().substring(0, 10),
  });
  const [bassin, setBassin] = useState("Todos");

  useEffect(() => {
    var url =
      "/api/totalAerateurs/" +
      new Date(dates.first).getTime() +
      "/" +
      new Date(dates.second).getTime() +
      "/" +
      bassin;
    console.log(url);
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setTableData(response.total);
        console.log(response.total);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [dates, majMaster, bassin]);

  return (
    <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
      <div className="flex justify-between border-b-2 mb-4">
        <h2 className="my-5">Total aireador</h2>
        <div className="flex flex-row">
          <section className="flex flex-col mt-2 justify-center mx-2">
            <label>Estanque</label>
            <select
              className="m-0 h-12"
              name="role"
              value={bassin}
              onChange={(e) => setBassin(e.target.value)}
            >
              <option key={201} value={"Todos"}>
                Todos
              </option>
              {bassinsList.map((Bassin, key) => (
                <option key={key} value={Bassin[1]}>
                  {Bassin[1]}
                </option>
              ))}
            </select>
          </section>
          <section className="flex flex-col mt-2 justify-center mx-2">
            <label>Fecha</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha"
              value={dates.first}
              onChange={(e) =>
                setDates({
                  ...dates,
                  first: e.target.value,
                })
              }
            />
          </section>
          <section className="flex flex-col mt-2 justify-center">
            <label>Fecha</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha"
              value={dates.second}
              onChange={(e) =>
                setDates({
                  ...dates,
                  second: e.target.value,
                })
              }
            />
          </section>
        </div>
      </div>
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Estanque
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Aireador
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Total en horas
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData
            ? tableData.map((r, key) => {
                return (
                  <tr
                    key={key}
                    className={
                      key % 2 === 0
                        ? "bg-white border-b hover:bg-gray-100"
                        : "bg-teal-50 border-b hover:bg-gray-100"
                    }
                  >
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      {r.Bassin}
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      {r.Aerateur + " de " + r.Puissance + " hp"}
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                      {parseInt(r["Total en horas"] * 10) / 10}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
    </div>
  );
}
