import { authFetch } from "auth";
import { toast } from "react-toastify";
import RowEditable from "components/RowEditable";

export default function ClientTable({ maj, setMaj, list, setList }) {
  const handleNewRow = (row, id) => {
    const i = list.findIndex((r) => r.id === id);
    var temp = [...list];
    temp[i] = {
      id: id,
      typeAliment: row[0].value,
      date: row[1].value,
      quantity: row[2].value,
      comment: row[3].value,
    };
    setList(temp);
    var url = "/api/ventesParJour";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id, comment: row[3].value }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  // Delete function
  const deleteRow = (id) => {
    var url = "/api/clients";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  return (
    <div>
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Typo de alimento
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Fecha
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Cantidad
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Comentario
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(({ id, typeAliment, date, quantity, comment }, key) => {
            const row = [
              {
                name: "typeAliment",
                value: typeAliment,
                editable: false,
              },
              {
                name: "date",
                value: date,
                editable: false,
              },
              {
                name: "quantity",
                value: quantity,
                editable: false,
              },
              {
                name: "comment",
                value: comment,
                editable: true,
                type: "input-text",
              },
            ];
            return (
              <RowEditable
                id={id}
                key={key}
                row={row}
                color={key % 2 === 1 ? "teal-50" : "white"}
                handleEdit={handleNewRow}
                deleteRow={deleteRow}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
