import { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";

export default function DateChart({ title, data, dateOption }) {
  const [series, setSeries] = useState(data);
  const [graphState, setGraphState] = useState({
    options: {
      chart: {
        id: "area-datetime" + title,
        type: "area",
        height: 400,
        zoom: {
          autoScaleYaxis: true,
        },
      },
      annotations: {
        yaxis: [
          {
            y: 30,
            borderColor: "#999",
            label: {
              show: true,
              text: "Support",
              style: {
                color: "#fff",
                background: "#00E396",
              },
            },
          },
        ],
        xaxis: [
          {
            x: dateOption.xStartDate,
            borderColor: "#999",
            yAxisIndex: 0,
            label: {
              show: true,
              text: "Rally",
              style: {
                color: "#fff",
                background: "#775DD0",
              },
            },
          },
        ],
      },
      colors: dateOption.colors,
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
      },
      xaxis: {
        type: "datetime",
        min: dateOption.xStartDate,
        tickAmount: 6,
      },
      tooltip: {
        x: {
          format: title === "O2" ? "dd MMM yyyy hh TT" : "dd MMM yyyy",
        },
        theme: dateOption.theme,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100],
        },
      },
    },
    selection: "one_year",
  });
  useEffect(() => {
    setSeries(data);
  }, [data]);

  const updateData = (timeline) => {
    setGraphState({ ...graphState, selection: timeline });

    let dateOneMonth = new Date();
    dateOneMonth.setMonth(dateOneMonth.getMonth() - 1);

    let dateOneWeek = new Date();
    dateOneWeek.setTime(dateOneWeek.getTime() - 604800000);

    let dateOneYear = new Date();
    dateOneYear.setMonth(dateOneYear.getMonth() - 12);

    switch (timeline) {
      case "one_week":
        ApexCharts.exec(
          "area-datetime" + title,
          "zoomX",
          dateOneWeek.getTime(),
          new Date().getTime()
        );
        break;
      case "one_month":
        ApexCharts.exec(
          "area-datetime" + title,
          "zoomX",
          dateOneMonth.getTime(),
          new Date().getTime()
        );
        break;
      case "one_year":
        ApexCharts.exec(
          "area-datetime" + title,
          "zoomX",
          dateOneYear.getTime(),
          new Date().getTime()
        );
        break;
      case "all":
        ApexCharts.exec(
          "area-datetime" + title,
          "zoomX",
          dateOption.xStartDate,
          new Date().getTime()
        );
        break;
      default:
    }
  };

  return (
    <div id={"chart" + title}>
      <div className={"toolbar" + title}>
        <h4 style={{ margin: "0" }}>{title}</h4>
        <button
          id="one_week"
          onClick={() => updateData("one_week")}
          className={graphState.selection === "one_week" ? "active" : ""}
        >
          1W
        </button>
        &nbsp;
        <button
          id="one_month"
          onClick={() => updateData("one_month")}
          className={graphState.selection === "one_month" ? "active" : ""}
        >
          1M
        </button>
        &nbsp;
        <button
          id="one_year"
          onClick={() => updateData("one_year")}
          className={graphState.selection === "one_year" ? "active" : ""}
        >
          1Y
        </button>
        &nbsp;
        <button
          id="all"
          onClick={() => updateData("all")}
          className={graphState.selection === "all" ? "active" : ""}
        >
          ALL
        </button>
      </div>

      <div id={"chart-timeline" + title}>
        <Chart
          options={graphState.options}
          series={series}
          type="area"
          height={400}
        />
      </div>
    </div>
  );
}
