import React, { useState, useEffect, useMemo, useCallback } from "react";

import { authFetch } from "auth";
import { toast } from "react-toastify";

import ShowErreurs from "components/Alertes/ShowErreurs";
import AlertesTable from "components/Alertes/AlertesTable";
import Loading from "components/Loading";

const alertesDict = {
  0: "Menos de 2 ppm a las 4 am",
  1: "Más de 9 ppm a las 5 pm ",
  2: "Caída de más de 30% en el valor de 4 am",
  3: "Aumento de más de 30% en el valor de 5 pm",
  4: "Temp. menor a 26°C o mayor a 33°C",
  10: "Falta de datos",
};

export default function Alertes() {
  // const [bassinsList, setBassinsList] = useState(["B03"]);
  const [alertes, setAlertes] = useState();
  const [erreurs, setErreurs] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [targetDate, setTargetDate] = useState(new Date());

  useEffect(() => {
    setIsLoading(true);
    let isCancelled = false;
    let date = targetDate.toISOString().substring(0, 10);
    var url = "/api/alertesBasiques/" + date;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        if (!isCancelled) {
          setAlertes(
            response.alertes.sort((a, b) => (a[0][1] > b[0][1] ? 1 : -1))
          );
          setErreurs(response.erreurs);
          setIsLoading(false);
        }
      })
      .catch((e) => toast.error(e.name + ": " + e.message));

    return () => {
      isCancelled = true;
    };
  }, [targetDate]);

  const transformCallback = useCallback((data) => {
    var dateAuj = new Date();
    var dateHier = new Date();
    dateHier.setDate(dateAuj.getDate() - 1);
    dateHier = dateHier.toISOString().substring(0, 10);
    dateAuj = dateAuj.toISOString().substring(0, 10);
    var temp = [];
    for (let i = 0; i < data.length; i++) {
      const alList = data[i];
      for (let j = 0; j < alList.length; j++) {
        const al = alList[j];
        const row = {
          Estanque: al[1],
          Alerta: alertesDict[al[2]],
          Ayer: al[3],
          Hoy: al[4],
        };
        temp = temp.concat(row);
      }
    }
    return temp;
  }, []);

  const dataAlertes = useMemo(() => {
    if (!alertes) {
      return [];
    }
    return transformCallback(alertes);
  }, [alertes, transformCallback]);

  const dataErreurs = useMemo(() => {
    if (!erreurs) {
      return [];
    }
    return transformCallback(erreurs);
  }, [erreurs, transformCallback]);

  if (isLoading) {
    return (
      <div className="mt-5">
        <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
          <div className="border-b-2  my-3 flex flex-rox justify-between py-1">
            <h2 className="p-0 pt-2">Alertas</h2>
            <div className="flex flex-row items-center justify-between ">
              <section className="flex flex-col mx-2">
                <label>Fecha inicio</label>
                <input
                  className="m-0"
                  type="date"
                  placeholder="Fecha dd/MM/yyyy"
                  value={targetDate.toISOString().substring(0, 10)}
                  onChange={(e) => setTargetDate(new Date(e.target.value))}
                />
              </section>
              <section className="mx-2 pt-4">
                {dataErreurs && dataErreurs.length > 0 ? (
                  <ShowErreurs erreurs={dataErreurs} />
                ) : null}{" "}
              </section>
            </div>
          </div>
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div className="mt-5">
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <div className="border-b-2  my-3 flex flex-rox justify-between py-1">
          <h2 className="p-0 pt-2">Alertas</h2>
          <div className="flex flex-row items-center justify-between ">
            <section className="flex flex-col mx-2">
              <label>Fecha inicio</label>
              <input
                className="m-0"
                type="date"
                placeholder="Fecha dd/MM/yyyy"
                value={targetDate.toISOString().substring(0, 10)}
                onChange={(e) => setTargetDate(new Date(e.target.value))}
              />
            </section>
            <section className="mx-2 pt-4">
              {dataErreurs && dataErreurs.length > 0 ? (
                <ShowErreurs erreurs={dataErreurs} />
              ) : null}{" "}
            </section>
          </div>
        </div>
        <AlertesTable data={dataAlertes} />
      </div>
    </div>
  );
}
