import { useState } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function AddCycleDialog({
  maj,
  setMaj,
  especesList,
  bassinsList,
}) {
  const [especeAdd, setEspeceAdd] = useState(especesList[0][0]);
  const [open, setOpen] = useState(false);
  const [bassin, setBassin] = useState(bassinsList[0][0]);
  const [date, setDate] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/cycles";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        Bassin: parseInt(bassin),
        espece: parseInt(especeAdd),
        Date: date,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
        setOpen(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  return (
    <div>
      <button className="btn-teal" type="button" onClick={() => setOpen(true)}>
        Añadir un ciclo
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2>Añadir un ciclo</h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <form className="mt-5" onSubmit={handleSubmit}>
                  <div className="md:grid grid-cols-4 gap-2 relative p-6">
                    <section className="flex flex-col">
                      <label>Estanque</label>
                      <select
                        className="m-0"
                        value={bassin}
                        onChange={(e) => setBassin(e.target.value)}
                      >
                        {bassinsList.map((Bassin, key) => {
                          return (
                            <option key={key} value={Bassin[0]}>
                              {Bassin[1]}
                            </option>
                          );
                        })}
                      </select>
                    </section>
                    <section className="flex flex-col">
                      <label>Especie</label>
                      <select
                        className="m-0"
                        value={especeAdd}
                        onChange={(e) => setEspeceAdd(e.target.value)}
                      >
                        {especesList.map((espece, key) => {
                          return (
                            <option key={key} value={espece[0]}>
                              {espece[1]}
                            </option>
                          );
                        })}
                      </select>
                    </section>
                    <section className="flex flex-col">
                      <label>Fecha</label>
                      <input
                        className="m-0"
                        type="date"
                        placeholder="Fecha"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </section>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="btn-red-transparent"
                      type="button"
                      onClick={() => setOpen(false)}
                    >
                      Cerrar
                    </button>
                    <button className="btn-teal" type="submit">
                      Confirmar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
