import React, { useRef } from "react";
import Footer from "./Footer";
import Routes from "./Routes";

export default function Sidebar(props) {
  const { logo, logoText, routes } = props;
  const sideBarRef = useRef(null);
  const openSidebarRef = useRef(null);
  const closeSideBarRef = useRef(null);

  function sidebarHandler(flag) {
    if (flag) {
      sideBarRef.current.style.transform = "translateX(0px)";
      openSidebarRef.current.classList.add("hidden");
      closeSideBarRef.current.classList.remove("hidden");
    } else {
      sideBarRef.current.style.transform = "translateX(-260px)";
      closeSideBarRef.current.classList.add("hidden");
      openSidebarRef.current.classList.remove("hidden");
    }
  }

  return (
    <div className="h-screen">
      <div className="w-56 absolute md:relative bg-gray-800 shadow lg:h-full flex-col justify-between hidden md:flex over">
        <div className="px-2 ">
          <div className="h-16 w-full flex items-center">
            <img src={logo} alt="logo" className="w-12" />
            <h2 className="text-3xl font-bold text-white pt-4">{logoText}</h2>
          </div>
        </div>
        <div className="overflow-auto px-2 h-full">
          <Routes list={routes} />
        </div>
        <Footer />
      </div>
      <div
        className="w-64 z-40 absolute bg-gray-800 shadow lg:h-full flex-col justify-between md:hidden transition duration-150 ease-in-out -translate-x-[260px]"
        ref={sideBarRef}
      >
        <button
          aria-label="toggle sidebar"
          ref={openSidebarRef}
          className="h-10 w-10 bg-gray-800 absolute right-0 mt-16 -mr-10 flex items-center shadow rounded-tr rounded-br justify-center cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 rounded focus:ring-gray-800"
          onClick={() => sidebarHandler(true)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-adjustments"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="#FFFFFF"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <circle cx="6" cy="10" r="2" />
            <line x1="6" y1="4" x2="6" y2="8" />
            <line x1="6" y1="12" x2="6" y2="20" />
            <circle cx="12" cy="16" r="2" />
            <line x1="12" y1="4" x2="12" y2="14" />
            <line x1="12" y1="18" x2="12" y2="20" />
            <circle cx="18" cy="7" r="2" />
            <line x1="18" y1="4" x2="18" y2="5" />
            <line x1="18" y1="9" x2="18" y2="20" />
          </svg>
        </button>
        <button
          aria-label="Close sidebar"
          ref={closeSideBarRef}
          className="hidden h-10 w-10 bg-gray-800 absolute right-0 mt-16 -mr-10  items-center shadow rounded-tr rounded-br justify-center cursor-pointer text-white"
          onClick={() => sidebarHandler(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-x"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </button>
        <div className="px-8">
          <div className="h-16 w-full flex items-center">
            <img src={logo} alt="logo" className="w-12" />
            <h2 className="text-2xl font-bold text-white">{logoText} Mob</h2>
          </div>
          <Routes list={routes} />
        </div>
      </div>
    </div>
  );
}
