import RowEditable from "components/RowEditable";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function TableLot({
  list,
  maj,
  setMaj,
  headers,
  deleteUrl,
  postUrl,
}) {
  const deleteRow = (id) => {
    var url = deleteUrl;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(~maj);
  };

  const handleEdit = (edited, id) => {
    var data = {};
    edited.forEach((e) => {
      if (e.name === "destino") {
        data[e.name] = e.value_id === 0 ? "V" : e.value_id === 1 ? "S" : "A";
        console.log(data[e.name]);
      } else {
        data[e.name] = e.value;
      }
    });
    var url = postUrl;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...data, id: id }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(~maj);
  };

  return (
    <>
      {/* Table items */}
      <table width="100%" className="min-w-full">
        <thead className="bg-teal-500 rounded">
          <tr>
            {headers.map((h, key) => {
              return (
                <th
                  key={key}
                  className="text-sm font-medium text-white px-6 py-4 text-left"
                >
                  {h}
                </th>
              );
            })}
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map((l, key) => {
            const row = headers.map((h) => {
              if (h === "typoestat") {
                return {
                  name: h,
                  value: l[h],
                  value_id: l[h] === "S" ? 0 : 1,
                  editable: true,
                  type: "select",
                  selectList: [
                    [0, "S"],
                    [1, "E"],
                  ],
                };
              } else if (h === "destino") {
                return {
                  name: h,
                  value:
                    l[h] === "V" ? "Venta" : l[h] === "S" ? "Siembra" : "Otro",
                  value_id: l[h] === "V" ? 0 : l[h] === "S" ? 1 : 2,
                  editable: true,
                  type: "select",
                  selectList: [
                    [0, "Venta"],
                    [1, "Siembra"],
                    [2, "Otro"],
                  ],
                };
              }
              if (h === "fecha") {
                return {
                  name: h,
                  value: l[h],
                  editable: true,
                  type: "input-date",
                };
              }
              var type =
                typeof l[h] === "number" ? "input-number" : "input-text-short";
              return {
                name: h,
                value: l[h],
                editable: true,
                type: type,
              };
            });
            return (
              <RowEditable
                key={l.id}
                id={l.id}
                row={row}
                color={key % 2 === 1 ? "teal-50" : "white"}
                handleEdit={handleEdit}
                deleteRow={deleteRow}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
}
