import React, { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function FormDialog({ cycle, setMaj, maj }) {
  const [open, setOpen] = useState(false);
  const [newLot, setNewLot] = useState({
    espece_id: "",
    cycle_id: cycle,
    commentaire: "",
  });
  const [especeList, setEspeceList] = useState([]);

  useEffect(() => {
    var url = "/api/especes";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setEspeceList(response.especes);
        setNewLot((old) => {
          return { ...old, espece_id: response.especes[0][0] };
        });
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/lots";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...newLot }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setOpen(false);
    setNewLot({
      espece_id: "",
      cycle_id: cycle,
      commentaire: "",
    });
  };

  return (
    <div>
      <button className="btn-teal" type="button" onClick={() => setOpen(true)}>
        Añadir un lote
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2>Añadir un lote</h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <form className="mt-5" onSubmit={handleSubmit}>
                  <div className="md:grid grid-cols-3 gap-2 relative p-6">
                    <section className="flex flex-col">
                      <label>Especie del lote</label>
                      <select
                        className="m-0"
                        value={newLot.espece_id}
                        onChange={(e) =>
                          setNewLot({ ...newLot, espece_id: e.target.value })
                        }
                      >
                        {especeList.map((e, key) => {
                          return (
                            <option key={key} value={e[0]}>
                              {e[1]}
                            </option>
                          );
                        })}
                      </select>
                    </section>
                    <section className="flex flex-col">
                      <label>Comentario</label>
                      <input
                        type="text"
                        value={newLot.commentaire}
                        onChange={(e) =>
                          setNewLot({ ...newLot, commentaire: e.target.value })
                        }
                      />
                    </section>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="btn-red-transparent"
                      type="button"
                      onClick={() => setOpen(false)}
                    >
                      Cerrar
                    </button>
                    <button className="btn-teal" type="submit">
                      Confirmar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
