import React, { useState, useEffect } from "react";
import { authFetch } from "auth";

import { useParams } from "react-router-dom";
import AncienCyclesTable from "components/Cycle/AncienCyclesTable";
import { toast } from "react-toastify";
import AncienLots from "components/Lots/AncienLots";
import Loading from "components/Loading";

export default function Cycles() {
  let { bassin, dateDebut, dateFin } = useParams();
  const headers = [
    "Estanque",
    "Fecha lleno",
    "Fecha vacio",
    "Superficie",
    "Especie",
    "# meses",
    "Peso sembrado",
    "Peso pescado",
    "Alimentacion Total",
    "Indice de conversion",
    "Mortalidad",
    "Rendimiento",
  ];
  const [tableData, setTableData] = useState("");
  const [maj, setMaj] = useState(true);
  const [lot, setLot] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var url = "/api/ancienCycle/" + bassin + "/" + dateDebut + "/" + dateFin;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setTableData(response.cycles);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [bassin, dateDebut, dateFin, maj]);

  const handleClick = (id) => {
    setLot(id);
  };

  const handleReset = () => {
    setLot(undefined);
    setMaj(~maj);
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <>
      {lot ? (
        <AncienLots cycle_={lot} resetCycle={handleReset} />
      ) : (
        <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
          <div className="flex justify-between border-b-2 mb-4">
            <h2 className="my-5">Ciclos</h2>
          </div>
          <AncienCyclesTable
            handleClick={handleClick}
            headers={headers}
            list={tableData.sort((a, b) => (a.Estanque > b.Estanque ? 1 : -1))}
            maj={maj}
            setMaj={setMaj}
          />
        </div>
      )}
    </>
  );
}
