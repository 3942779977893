import { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";
import TableVentesPoisson from "components/TableVentesPoisson";
import Loading from "components/Loading";

export default function Venta() {
  const [ventePoisson, setVentePoisson] = useState({
    date: new Date().toISOString().substring(0, 10),
    poids: "",
    espece: 1,
  });
  const [venteJour, setVenteJour] = useState("");
  const [filtre, setFiltre] = useState(1);
  const [tableData, setTableData] = useState("");
  const [maj, setMaj] = useState(true);
  const [especesList, setEspecesList] = useState([""]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var url = "/api/especes/poissons";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setEspecesList(response.especes);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, []);

  useEffect(() => {
    var url = "/api/ventes/poissons/" + filtre;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setTableData(response.ventes);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [maj, filtre]);

  useEffect(() => {
    if (ventePoisson.date !== "") {
      let f = ventePoisson.espece !== "" ? ventePoisson.espece : filtre;
      var url = "/api/ventes/poissonsJour/" + f + "/" + ventePoisson.date;
      authFetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((data) => {
              throw new Error(data.message);
            });
          }
        })
        .then((response) => {
          setVenteJour(response.total);
        })
        .catch((e) => toast.error(e.name + ": " + e.message));
    }
  }, [ventePoisson, filtre]);

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/ventes/poissons";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(ventePoisson),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  const handleChangeDate = (d, e) => {};

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <div className="flex justify-between border-b-2 mb-4">
          <h2 className="mt-5">Añadir una venta de pescados</h2>
        </div>
        <form className="md:grid grid-cols-5 gap-10" onSubmit={handleSubmit}>
          <section className="flex flex-col">
            <label>Espece</label>
            <select
              className="m-0"
              name="role"
              value={ventePoisson.espece}
              onChange={(e) => {
                setVentePoisson({
                  ...ventePoisson,
                  espece: parseInt(e.target.value),
                });
                setVenteJour("");
                setFiltre(parseInt(e.target.value));
                handleChangeDate(ventePoisson.date, parseInt(e.target.value));
              }}
              placeholder="Cliente"
            >
              {especesList.map((e, key) => {
                return (
                  <option key={key} value={e[0]}>
                    {e[1]}
                  </option>
                );
              })}
            </select>
          </section>
          <section className="flex flex-col">
            <label>Fecha</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha dd/MM/yyyy"
              value={ventePoisson.date}
              onChange={(e) => {
                setVentePoisson({
                  ...ventePoisson,
                  date: e.target.value,
                });
                handleChangeDate(e.target.value, "");
              }}
            />
          </section>
          <section className="flex flex-col">
            <label>Peso total</label>
            <input
              className="m-0"
              type="text"
              name="Peso total"
              value={ventePoisson.poids}
              onChange={(e) =>
                setVentePoisson({
                  ...ventePoisson,
                  poids: e.target.value,
                })
              }
              placeholder="Peso total"
            />
          </section>
          <section className="flex justify-center items-center">
            {venteJour !== "" ? (
              <p>
                Peso total pesca: <b>{venteJour}</b>
              </p>
            ) : null}
          </section>
          <button className="btn-teal" type="submit">
            Confirmar
          </button>
        </form>
      </div>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <div className="flex justify-between border-b-2 mb-4">
          <h2 className="mt-5">Venta de pescados</h2>
          <div className="flex flex-row">
            <section className="flex flex-col mt-2 justify-center">
              <label> Filtrar por especies</label>
              <select
                className="m-0"
                name="role"
                value={filtre}
                onChange={(e) => setFiltre(e.target.value)}
                placeholder="Cliente"
              >
                {especesList.map((e, key) => {
                  return (
                    <option key={key} value={e[0]}>
                      {e[1]}
                    </option>
                  );
                })}
              </select>
            </section>
          </div>
        </div>
        <TableVentesPoisson maj={maj} setMaj={setMaj} list={tableData} />
      </div>
    </>
  );
}
