import { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";
import TableVentesCrevettes from "components/TableVentesCrevettes";
import Loading from "components/Loading";

export default function Venta() {
  const [venteCrevette, setVenteCrevette] = useState({
    date: new Date().toISOString().substring(0, 10),
    u5: "",
    u6: "",
    u8: "",
    u10: "",
    u12: "",
    u15: "",
    u16_u20: "",
    u21_u25: "",
    decortique: "",
  });
  const [tableData, setTableData] = useState("");
  const [maj, setMaj] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var url = "/api/ventes/crevettes";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setTableData(response.ventes);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [maj]);

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/ventes/crevettes";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        u5: venteCrevette.u5 === "" ? 0 : venteCrevette.u5,
        u6: venteCrevette.u6 === "" ? 0 : venteCrevette.u6,
        u8: venteCrevette.u8 === "" ? 0 : venteCrevette.u8,
        u10: venteCrevette.u10 === "" ? 0 : venteCrevette.u10,
        u12: venteCrevette.u12 === "" ? 0 : venteCrevette.u12,
        u15: venteCrevette.u15 === "" ? 0 : venteCrevette.u15,
        u16_u20: venteCrevette.u16_u20 === "" ? 0 : venteCrevette.u16_u20,
        u21_u25: venteCrevette.u21_u25 === "" ? 0 : venteCrevette.u21_u25,
        decortique:
          venteCrevette.decortique === "" ? 0 : venteCrevette.decortique,
        date: venteCrevette.date,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        if (response.message === "Fecha ya introducida") {
          toast.warn(response.message);
        } else {
          toast.success(response.message);
          setMaj(~maj);
          setVenteCrevette({
            date: new Date().toISOString().substring(0, 10),
            u5: "",
            u6: "",
            u8: "",
            u10: "",
            u12: "",
            u15: "",
            u16_u20: "",
            u21_u25: "",
            decortique: "",
          });
        }
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <div className="flex justify-between border-b-2 mb-4">
          <h2 className="my-5">Añadir una venta de camarones</h2>
        </div>
        <form className="md:grid grid-cols-11 gap-2" onSubmit={handleSubmit}>
          <section className="flex flex-col">
            <label>Fecha</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha dd/MM/yyyy"
              value={venteCrevette.date}
              onChange={(e) => {
                setVenteCrevette({
                  ...venteCrevette,
                  date: e.target.value,
                });
              }}
            />
          </section>
          <section className="flex flex-col">
            <label>u5</label>
            <input
              className="m-0"
              type="number"
              name="u5"
              value={venteCrevette.u5}
              onChange={(e) =>
                setVenteCrevette({
                  ...venteCrevette,
                  u5: e.target.value,
                })
              }
              placeholder="u5"
            />
          </section>
          <section className="flex flex-col">
            <label>u6</label>
            <input
              className="m-0"
              type="number"
              name="u6"
              value={venteCrevette.u6}
              onChange={(e) =>
                setVenteCrevette({
                  ...venteCrevette,
                  u6: e.target.value,
                })
              }
              placeholder="u6"
            />
          </section>
          <section className="flex flex-col">
            <label>u8</label>
            <input
              className="m-0"
              type="number"
              name="u8"
              value={venteCrevette.u8}
              onChange={(e) =>
                setVenteCrevette({
                  ...venteCrevette,
                  u8: e.target.value,
                })
              }
              placeholder="u8"
            />
          </section>
          <section className="flex flex-col">
            <label>u10</label>
            <input
              className="m-0"
              type="number"
              name="u10"
              value={venteCrevette.u10}
              onChange={(e) =>
                setVenteCrevette({
                  ...venteCrevette,
                  u10: e.target.value,
                })
              }
              placeholder="u10"
            />
          </section>
          <section className="flex flex-col">
            <label>u12</label>
            <input
              className="m-0"
              type="number"
              name="u12"
              value={venteCrevette.u12}
              onChange={(e) =>
                setVenteCrevette({
                  ...venteCrevette,
                  u12: e.target.value,
                })
              }
              placeholder="u12"
            />
          </section>
          <section className="flex flex-col">
            <label>u15</label>
            <input
              className="m-0"
              type="number"
              name="u15"
              value={venteCrevette.u15}
              onChange={(e) =>
                setVenteCrevette({
                  ...venteCrevette,
                  u15: e.target.value,
                })
              }
              placeholder="u15"
            />
          </section>
          <section className="flex flex-col">
            <label>u16_u20</label>
            <input
              className="m-0"
              type="number"
              name="u16_u20"
              value={venteCrevette.u16_u20}
              onChange={(e) =>
                setVenteCrevette({
                  ...venteCrevette,
                  u16_u20: e.target.value,
                })
              }
              placeholder="u16_u20"
            />
          </section>
          <section className="flex flex-col">
            <label>u21_u25</label>
            <input
              className="m-0"
              type="number"
              name="u21_u25"
              value={venteCrevette.u21_u25}
              onChange={(e) =>
                setVenteCrevette({
                  ...venteCrevette,
                  u21_u25: e.target.value,
                })
              }
              placeholder="u21_u25"
            />
          </section>
          <section className="flex flex-col">
            <label>Pelado</label>
            <input
              className="m-0"
              type="number"
              name="pelado"
              value={venteCrevette.decortique}
              onChange={(e) =>
                setVenteCrevette({
                  ...venteCrevette,
                  decortique: e.target.value,
                })
              }
              placeholder="Pelado"
            />
          </section>
          <button className="btn-teal" type="submit">
            Confirmar
          </button>
        </form>
      </div>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <div className="flex justify-between border-b-2 mb-4">
          <h2 className="my-5">Venta de camarones</h2>
        </div>
        <TableVentesCrevettes maj={maj} setMaj={setMaj} list={tableData} />
      </div>
    </>
  );
}
