import RowEditable from "components/RowEditable";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function AncienCyclesTable({
  list,
  headers,
  maj,
  setMaj,
  handleClick,
}) {
  const handleEdit = (edited, id) => {
    var data = {
      id: id,
      Compras: edited[2].value,
      Comentario: edited[6].value,
    };
    var url = "/api/stocks";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(~maj);
  };

  return (
    <table width="100%" className="min-w-full ">
      <thead className="bg-teal-500 rounded">
        <tr>
          {headers.map((h, key) => {
            return (
              <th
                key={key}
                className="text-sm font-medium text-white px-1 py-4 text-left w-6"
              >
                {h}
              </th>
            );
          })}
          <th className="w-10" />
          <th className="w-10" />
        </tr>
      </thead>
      <tbody>
        {list.map(
          (
            {
              id,
              Estanque,
              FechaLleno,
              FechaVacio,
              meses,
              Superficie,
              Especie,
              PesoSembrado,
              PesoPescado,
              Mortalidad,
              Rendimiento,
              IndiceConversion,
              AlimentacionTotal,
            },
            key
          ) => {
            const row = [
              {
                name: "Estanque",
                value: Estanque,
                editable: true,
                type: "input-text",
              },
              {
                name: "Fecha lleno",
                value: FechaLleno,
                editable: false,
                type: "input-date",
              },
              {
                name: "Fecha vacio",
                value: FechaVacio,
                editable: false,
                type: "input-date",
              },
              {
                name: "Superficie",
                value: Superficie,
                editable: true,
                type: "input-number",
              },
              {
                name: "Especie",
                value: Especie,
                editable: false,
              },
              {
                name: "meses",
                value: meses,
                editable: false,
              },
              {
                name: "PesoSembrado",
                value: PesoSembrado,
                editable: true,
                type: "input-number",
              },
              {
                name: "PesoPescado",
                value: PesoPescado,
                editable: false,
              },
              {
                name: "AlimentacionTotal",
                value: AlimentacionTotal,
                editable: false,
              },
              {
                name: "IndiceConversion",
                value: IndiceConversion,
                editable: false,
              },
              {
                name: "Mortalidad",
                value: Mortalidad,
                editable: false,
              },
              {
                name: "Rendimiento",
                value: Rendimiento,
                editable: false,
              },
            ];
            return (
              <RowEditable
                key={id}
                id={id}
                row={row}
                handleClick={handleClick}
                color={key % 2 === 1 ? "teal-50" : "white"}
                handleEdit={handleEdit}
              />
            );
          }
        )}
      </tbody>
    </table>
  );
}
