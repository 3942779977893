import React, { useState, useEffect } from "react";
import { authFetch } from "auth";
import DateChart from "components/DateChart";
import { toast } from "react-toastify";

export default function AnalysesEau({ bassinDefault, inDialog }) {
  const [bassinsList, setBassinsList] = useState(["B03"]);
  const [bassin, setBassin] = useState(
    bassinDefault !== undefined ? bassinDefault : "B03"
  );
  const [temps4, setTemps4] = useState(["102"]);
  const [temps17, setTemps17] = useState(["102"]);
  const [O2, setO2] = useState([]);
  const [ph, setPh] = useState(["102"]);
  const [SE, setSE] = useState(["102"]);
  const [majBassin, setMajBassin] = useState(false);

  let dateOneYear = new Date();
  dateOneYear.setMonth(dateOneYear.getMonth() - 12);

  useEffect(() => {
    var url = "/api/bassins";
    authFetch(url)
      .then((response) => {
        if (response.status === 401) {
          toast.error("Sorry you aren't authorized!");
          return null;
        } else if (response.status === 500) {
          toast.warn("Bad request");
          return null;
        }
        return response.json();
      })
      .then((response) => {
        setBassinsList(response.bassins);
        setMajBassin(true);
      });
  }, []);

  useEffect(() => {
    if (majBassin) {
      var url =
        "/api/analyseEauGraph/" +
        bassin +
        "/" +
        dateOneYear.toISOString().substring(0, 10);
      authFetch(url)
        .then((response) => {
          if (response.status === 401) {
            toast.error("Sorry you aren't authorized!");
            return null;
          }
          return response.json();
        })
        .then((response) => {
          setTemps4(response.temps4);
          setTemps17(response.temps17);
          setO2(response.oxygene);
          setPh(response.ph);
          setSE(response.se);
        });
    }
  }, [majBassin, bassin]);

  const handleBassinChange = (e) => {
    setBassin(e.target.value);
  };

  return (
    <div className="">
      <div className="flex justify-center">
        <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
          <section className="flex flex-col">
            <label>Estanque</label>
            <select
              className="mb-0"
              name="bassin"
              value={bassin}
              onChange={handleBassinChange}
            >
              {bassinsList.map((Bassin, key) => {
                return (
                  <option key={key} value={Bassin}>
                    {Bassin}
                  </option>
                );
              })}
            </select>
          </section>
        </div>
      </div>
      <div className={inDialog ? "flex flex-col" : "md:grid grid-cols-2 "}>
        <div className="bg-green-500 shadow-md rounded-lg px-5 py-2 m-2">
          <div className="flex justify-between border-b-2 mb-4">
            <h2 className="mt-5 text-white">Temperatura 4a.m</h2>
          </div>
          {temps4 && temps4.length !== 0 ? (
            <DateChart
              title={"temps4"}
              data={[
                {
                  name: "temps4",
                  data: temps4,
                },
              ]}
              dateOption={{
                xStartDate: new Date(
                  dateOneYear.toISOString().substring(0, 10)
                ).getTime(),
                colors: ["#fff"],
                theme: "dark",
              }}
            />
          ) : (
            <h2>No hay datos de temperatura a 4a.m</h2>
          )}
        </div>
        <div className="bg-teal-500 shadow-md rounded-lg px-5 py-2 m-2">
          <div className="flex justify-between border-b-2 mb-4">
            <h2 className="mt-5 text-white">Temperatura 5p.m</h2>
          </div>
          {temps17 && temps17.length !== 0 ? (
            <DateChart
              title={"temps17"}
              data={[
                {
                  name: "temps17",
                  data: temps17,
                },
              ]}
              dateOption={{
                xStartDate: new Date(
                  dateOneYear.toISOString().substring(0, 10)
                ).getTime(),
                colors: ["#fff"],
                theme: "dark",
              }}
            />
          ) : (
            <h2>No hay datos de temperatura a 5p.m</h2>
          )}{" "}
        </div>

        <div className="bg-orange-400 shadow-md rounded-lg px-5 py-2 m-2">
          <div className="flex justify-between border-b-2 mb-4">
            <h2 className="mt-5 text-white">O2</h2>
          </div>
          {O2 && O2.length !== 0 ? (
            <DateChart
              title={"O2"}
              data={[
                {
                  name: "O2",
                  data: O2,
                },
              ]}
              dateOption={{
                xStartDate: new Date(
                  dateOneYear.toISOString().substring(0, 10)
                ).getTime(),
                colors: ["#fff"],
                theme: "dark",
              }}
            />
          ) : (
            <h2>No hay datos de O2</h2>
          )}
        </div>
        <div className="bg-blue-400 shadow-md rounded-lg px-5 py-2 m-2">
          <div className="flex justify-between border-b-2 mb-4">
            <h2 className="mt-5 text-white">PH</h2>
          </div>
          {ph && ph.length !== 0 ? (
            <DateChart
              title={"PH"}
              data={[
                {
                  name: "PH",
                  data: ph,
                },
              ]}
              dateOption={{
                xStartDate: new Date(
                  dateOneYear.toISOString().substring(0, 10)
                ).getTime(),
                colors: ["#fff"],
                theme: "dark",
              }}
            />
          ) : (
            <h2>No hay datos de PH</h2>
          )}
        </div>
        <div className="bg-red-500 shadow-md rounded-lg px-5 py-2 m-2">
          <div className="flex justify-between border-b-2 mb-4">
            <h2 className="mt-5 text-white">SE</h2>
          </div>
          {SE && SE.length !== 0 ? (
            <DateChart
              title={"SE"}
              data={[
                {
                  name: "SE",
                  data: SE,
                },
              ]}
              dateOption={{
                xStartDate: new Date(
                  dateOneYear.toISOString().substring(0, 10)
                ).getTime(),
                colors: ["#fff"],
                theme: "dark",
              }}
            />
          ) : (
            <h2>No hay datos de SE</h2>
          )}
        </div>
      </div>
    </div>
  );
}
