import { useState, useEffect } from "react";
import { authFetch } from "auth";
import ShowAeration from "components/Aerateur/ShowAeration";
import ShowTotalAeration from "components/Aerateur/ShowTotalAeration";
import { toast } from "react-toastify";
import Loading from "components/Loading";

export default function AddAeration() {
  const [aeration, setAeration] = useState({
    bassin: 1,
    date: new Date().toISOString().substring(0, 16),
    aerateur: 1,
  });
  const [bassinsList, setBassinsList] = useState([""]);
  const [aerateursList, setAerateursList] = useState([""]);
  const [majMaster, setMajMaster] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var url = "/api/aerateursList";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setBassinsList(response.bassins.sort((a, b) => (a[1] > b[1] ? 1 : -1)));
        setAerateursList(
          response.aerateurs.sort((a, b) => (a[1] > b[1] ? 1 : -1))
        );
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [majMaster]);

  const handleConfirm = () => {
    var date = new Date(aeration.date).getTime();

    var url = "/api/aerateursList";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...aeration,
        date: date,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMajMaster(~majMaster);
        setAeration({
          bassin: 1,
          date: new Date().toISOString().substring(0, 16),
          aerateur: 1,
        });
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <div className="flex justify-between border-b-2 mb-4">
          <h2 className="my-5">Añadir un uso de aireador</h2>
        </div>
        <div className="md:grid grid-cols-4 gap-2">
          <section className="flex flex-col">
            <label>Estanque</label>
            <select
              className="m-0 h-12"
              name="role"
              value={aeration.bassin}
              onChange={(e) =>
                setAeration({
                  ...aeration,
                  bassin: e.target.value,
                })
              }
            >
              {bassinsList.map((Bassin, key) => (
                <option key={key} value={Bassin[0]}>
                  {Bassin[1]}
                </option>
              ))}
            </select>
          </section>
          <section className="flex flex-col">
            <label>Aireador</label>
            <select
              className="m-0  h-12"
              name="role"
              value={aeration.aerateur}
              onChange={(e) =>
                setAeration({
                  ...aeration,
                  aerateur: e.target.value,
                })
              }
            >
              {aerateursList.map((aerateur, key) => (
                <option key={key} value={aerateur[0]}>
                  {aerateur[1] + "| " + aerateur[2] + " hp"}
                </option>
              ))}
            </select>
          </section>
          <section className="flex flex-col">
            <label>Fecha</label>
            <input
              className="m-0 h-12"
              type="datetime-local"
              value={aeration.date}
              onChange={(e) =>
                setAeration({
                  ...aeration,
                  date: e.target.value,
                })
              }
            />
          </section>
          <button
            onClick={handleConfirm}
            className="btn-teal h-12 self-center mt-5 max-w-xs"
          >
            Añadir
          </button>
        </div>
      </div>
      <ShowAeration
        bassinsList={bassinsList}
        aerateursList={aerateursList}
        maj={majMaster}
        setMaj={setMajMaster}
      />
      <ShowTotalAeration majMaster={majMaster} bassinsList={bassinsList} />
    </div>
  );
}
