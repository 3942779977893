import { useState, useEffect } from "react";
import { authFetch } from "auth";
import ShowAeration from "./ShowAeration";
import { toast } from "react-toastify";

export default function PredictionsTable({ data }) {
  const [open, setOpen] = useState(false);
  const [selectedBassin, setSelectedBassin] = useState(undefined);

  const [list, setList] = useState([]);

  const handleClick = (bassin) => {
    setOpen(true);
    setSelectedBassin(bassin);
  };

  useEffect(() => {
    var date = new Date();
    date.setDate(date.getDate() + 1);
    const today = date.getTime();
    date.setDate(date.getDate() - 11);
    var before = date.getTime();
    var url = "/api/aerateurs/" + before + "/" + today + "/Todos";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setList(
          response.aeration.sort((a, b) =>
            a.Fecha_inicio < b.Fecha_inicio ? 1 : -1
          )
        );
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, []);

  return (
    <div>
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Estanque
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Alerta
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Ayer
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Hoy
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {data
            ? data.map((r, key) => {
                return (
                  <tr
                    id={r.Estanque}
                    key={key}
                    className={
                      key % 2 === 0
                        ? "bg-white border-b hover:bg-gray-100"
                        : "bg-teal-50 border-b hover:bg-gray-100"
                    }
                    onClick={(e) => handleClick(e.target.id)}
                  >
                    <td
                      id={r.Estanque}
                      className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap"
                    >
                      {r.Estanque}
                    </td>
                    <td
                      id={r.Estanque}
                      className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap"
                    >
                      {r.Alerta}
                    </td>
                    <td
                      id={r.Estanque}
                      className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap"
                    >
                      {r.Ayer}
                    </td>
                    <td
                      id={r.Estanque}
                      className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap"
                    >
                      {r.Hoy}
                    </td>
                  </tr>
                );
              })
            : null}
        </tbody>
      </table>
      <ShowAeration
        open={open}
        setOpen={setOpen}
        bassin={selectedBassin}
        aerations={list.filter((a) => a.Estanque === selectedBassin)}
      />
    </div>
  );
}
