import React, { useState } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function FormDialog({ alimentsList, setMaj, maj }) {
  const [open, setOpen] = useState(false);
  const [newInventaire, setNewInventaire] = useState({
    id: 2,
    libelle: "",
    date: "",
    stock: "",
    commentaire: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/stocks";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...newInventaire,
        stock: parseInt(newInventaire.stock),
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setNewInventaire({
      id: 2,
      libelle: "",
      stock: "",
      commentaire: "",
      date: "",
    });
  };
  return (
    <div className="">
      <button
        className="btn-red px-4"
        type="button"
        onClick={() => setOpen(true)}
      >
        Hacer el inventario
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2>Hacer el inventario</h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <form
                  className="mt-5"
                  onSubmit={handleSubmit}
                  id="changeAliment"
                >
                  <div className="md:grid grid-cols-4 gap-2 relative p-6">
                    <section className="flex flex-col">
                      <label>Alimento</label>
                      <select
                        className="m-0"
                        name="role"
                        value={newInventaire.id}
                        onChange={(e) =>
                          setNewInventaire({
                            ...newInventaire,
                            id: e.target.value,
                          })
                        }
                        placeholder="Alimento"
                      >
                        {alimentsList.map((aliment, key) => {
                          return (
                            <option key={key} value={aliment[0]}>
                              {aliment[1]}
                            </option>
                          );
                        })}
                      </select>
                    </section>
                    <section className="flex flex-col">
                      <label>Fecha</label>
                      <input
                        className="m-0"
                        type="date"
                        placeholder="Fecha"
                        value={newInventaire.date}
                        onChange={(e) => {
                          setNewInventaire({
                            ...newInventaire,
                            date: e.target.value,
                          });
                        }}
                      />
                    </section>
                    <section className="flex flex-col">
                      <label>Cantidad</label>
                      <input
                        type="text"
                        name="name"
                        value={newInventaire.stock}
                        onChange={(e) => {
                          setNewInventaire({
                            ...newInventaire,
                            stock: e.target.value,
                          });
                        }}
                        placeholder="Cantidad"
                      />
                    </section>
                    <section className="flex flex-col">
                      <label>Comentario</label>
                      <input
                        type="text"
                        name="name"
                        value={newInventaire.commentaire}
                        onChange={(e) => {
                          setNewInventaire({
                            ...newInventaire,
                            commentaire: e.target.value,
                          });
                        }}
                        placeholder="Comentario"
                      />
                    </section>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="btn-red-transparent"
                      type="button"
                      onClick={() => setOpen(false)}
                    >
                      Cerrar
                    </button>
                    <button className="btn-teal" type="submit">
                      Confirmar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
