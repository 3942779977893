import React, { useState, useEffect } from "react";
import { authFetch } from "../../auth";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";

export default function Routes({ list }) {
  const [role, setRole] = useState(undefined);

  useEffect(() => {
    var url = "/api/user";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setRole(response.role);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, []);

  return (
    <ul className="">
      {list.map((r, key) => {
        if (!r.roles.includes(role)) {
          return null;
        }
        return (
          <li
            className="flex w-full justify-between cursor-pointer items-center"
            key={key}
          >
            <NavLink
              to={r.layout + r.path}
              className={(isActive) =>
                !isActive
                  ? "flex items-center w-full px-1 py-4  hover:text-white text-gray-400"
                  : "flex items-center w-full px-1 py-4  text-white"
              }
              key={key}
            >
              {<r.icon className="text-xl" />}
              <span className="text-sm ml-2">{r.name}</span>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}
