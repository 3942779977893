import { useState, useEffect } from "react";
import { authFetch } from "auth";
import Clients from "../components/Client/Clients";
import VentesParJour from "../components/VentesParJour";
import CreateInvoice from "components/CreateInvoice";
import { toast } from "react-toastify";
import AddClientDialog from "components/Client/AddClientDialog";
import Loading from "components/Loading";
import AddAlimentDialog from "components/Dialog/AddAlimentDialog";

export default function VenteAliment() {
  const [clientsList, setClientsList] = useState([]);
  const [maj, setMaj] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [typeAlimentList, setTypeAlimentList] = useState([]);

  useEffect(() => {
    var url = "/api/ventes/aliments";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setTypeAlimentList(response.typeAliment);
        const l = response.clients.sort((a, b) =>
          a[1].trim().localeCompare(b[1].trim())
        );
        setClientsList(l);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [maj]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <h2 className="border-b-2  my-5">Facturacion</h2>
        <CreateInvoice
          maj={maj}
          setMaj={setMaj}
          clientsList={clientsList}
          typeAlimentList={typeAlimentList}
        />
      </div>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <div className="flex flex-row justify-between border-b-2 mb-5 mt-3 items-center">
          <h2 className="">Gestión de los clientes</h2>
          <AddClientDialog maj={maj} setMaj={setMaj} />
        </div>
        <Clients clientsList={clientsList} maj={maj} setMaj={setMaj} />
      </div>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <VentesParJour
          maj={maj}
          setMaj={setMaj}
          typeAlimentList={typeAlimentList}
        />
      </div>
    </>
  );
}
