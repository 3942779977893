import RowEditable from "components/RowEditable";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function TableChangementEau({
  list,
  bassinsList,
  pompesList,
  maj,
  setMaj,
}) {
  // Delete function
  const deleteRow = (id) => {
    var url = "/api/changementEau";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  const handleEdit = (edited, id) => {
    var data = {
      id: id,
      bassin: edited[0].value_id,
      pompe: edited[1].value_id,
      date: edited[2].value,
      heures: edited[3].value,
      type: edited[4].value,
    };
    var url = "/api/changementEau";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  return (
    <>
      {/* Table items */}
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white pr-10 pl-2 py-4 text-left">
              Estanque
            </th>
            <th className="text-sm font-medium text-white pr-10 pl-2 py-4 text-left">
              Bomba
            </th>
            <th className="text-sm font-medium text-white pr-10 pl-2 py-4 text-left">
              Fecha
            </th>
            <th className="text-sm font-medium text-white pr-10 pl-2 py-4 text-left">
              Horas
            </th>
            <th className="text-sm font-medium text-white pr-10 pl-2 py-4 text-left">
              Tipo
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(
            (
              {
                id,
                Estanque,
                Estanque_id,
                Pompe,
                Pompe_id,
                Fecha,
                Heures,
                Type,
              },
              key
            ) => {
              const row = [
                {
                  name: "Estanque",
                  value: Estanque,
                  value_id: Estanque_id,
                  editable: true,
                  type: "select",
                  selectList: bassinsList,
                },
                {
                  name: "Bomba",
                  value: Pompe,
                  value_id: Pompe_id,
                  editable: true,
                  type: "select",
                  selectList: pompesList,
                },
                {
                  name: "Fecha",
                  value: Fecha,
                  editable: true,
                  type: "input-date",
                },
                {
                  name: "Horas",
                  value: parseFloat(Heures),
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "Tipo",
                  value: Type,
                  value_id: Type === "L" ? 0 : 1,
                  editable: true,
                  type: "select",
                  selectList: [
                    [0, "L"],
                    [1, "R"],
                  ],
                },
              ];
              return (
                <RowEditable
                  key={id}
                  id={id}
                  row={row}
                  color={key % 2 === 1 ? "teal-50" : "white"}
                  handleEdit={handleEdit}
                  deleteRow={deleteRow}
                />
              );
            }
          )}
        </tbody>
      </table>
    </>
  );
}
