import { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";
import RowEditable from "components/RowEditable";

export default function UsersList({ majTop, setMajTop, roleList }) {
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    authFetch("/api/user/list")
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setUserList(response.usersList);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [majTop]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-full">
        <div class="loader"></div>
      </div>
    );
  }

  const handleNewRow = (edited, id) => {
    var url = "/api/user/list";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: id,
        name: edited[0].value,
        role_id: edited[1].value_id,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMajTop(~majTop);
  };

  // Delete function
  const deleteRow = (id) => {
    var url = "/api/user/list";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMajTop(~majTop);
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-5 m-2">
      <h2 className="border-b-2 ">Lista de usarios</h2>
      <div className="mt-4">
        <table width="100%" className="min-w-full ">
          <thead className="bg-teal-500 rounded">
            <tr>
              <th className="text-sm font-medium text-white px-6 py-4 text-left">
                Nombre
              </th>
              <th className="text-sm font-medium text-white px-6 py-4 text-left">
                Función
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {userList.map(({ id, name, role, role_id }, key) => {
              const row = [
                {
                  name: "name",
                  value: name,
                  editable: true,
                  type: "input-text",
                },
                {
                  name: "role",
                  value: role,
                  value_id: role_id,
                  editable: true,
                  type: "select",
                  selectList: roleList,
                },
              ];
              return (
                <RowEditable
                  id={id}
                  key={key}
                  row={row}
                  color={key % 2 === 1 ? "teal-50" : "white"}
                  handleEdit={handleNewRow}
                  deleteRow={deleteRow}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
