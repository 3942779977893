import React, { useState } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function FormDialog({ lot, setMaj, maj, espece }) {
  const [open, setOpen] = useState(false);
  const [peches, setPeches] = useState({
    date: "",
    poids: "",
    quantitelb: "",
    quantite: "",
    commentaire: "",
    destination: "V",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/peches";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...peches,
        date: peches.date,
        poids: parseFloat(peches.poids),
        quantite:
          espece.substring(0, 9) === "Juvéniles" ||
          espece.substring(0, 10) === "Postlarves"
            ? parseFloat(peches.quantitelb) * parseFloat(peches.poids)
            : parseFloat(peches.quantite),
        lot: lot,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(maj + 1);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setOpen(false);
    setPeches({
      date: "",
      poids: "",
      quantitelb: "",
      commentaire: "",
      destination: "V",
    });
  };

  return (
    <div>
      <button className="btn-teal" type="button" onClick={() => setOpen(true)}>
        Añadir una pesca
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2>Añadir una pesca</h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <form className="mt-5" onSubmit={handleSubmit}>
                  <div className="md:grid grid-cols-3 gap-2 relative p-6">
                    <section className="flex flex-col">
                      <label>Fecha</label>
                      <input
                        required
                        className="m-0"
                        type="date"
                        placeholder="Fecha"
                        value={peches.date}
                        onChange={(e) =>
                          setPeches({ ...peches, date: e.target.value })
                        }
                      />
                    </section>
                    <section className="flex flex-col">
                      <label>Peso total</label>
                      <input
                        type="text"
                        value={peches.poids}
                        onChange={(e) =>
                          setPeches({
                            ...peches,
                            poids: e.target.value,
                          })
                        }
                      />
                    </section>
                    {espece &&
                    (espece.substring(0, 9) === "Juvéniles" ||
                      espece.substring(0, 10) === "Postlarves") ? (
                      <section className="flex flex-col">
                        <label>Cantitad por libras</label>
                        <input
                          type="text"
                          value={peches.quantitelb}
                          onChange={(e) =>
                            setPeches({
                              ...peches,
                              quantitelb: e.target.value,
                            })
                          }
                        />
                      </section>
                    ) : (
                      <section className="flex flex-col">
                        <label>Cantidad total</label>
                        <input
                          type="text"
                          value={peches.quantite}
                          onChange={(e) =>
                            setPeches({
                              ...peches,
                              quantite: e.target.value,
                            })
                          }
                        />
                      </section>
                    )}
                    <section className="flex flex-col">
                      <label>Comentario</label>
                      <input
                        type="text"
                        value={peches.commentaire}
                        onChange={(e) =>
                          setPeches({ ...peches, commentaire: e.target.value })
                        }
                      />
                    </section>
                    <section className="flex flex-col">
                      <label>Destino</label>
                      <select
                        className="m-0"
                        value={peches.destination}
                        onChange={(e) =>
                          setPeches({ ...peches, destination: e.target.value })
                        }
                      >
                        <option value={"V"}>Venta</option>
                        <option value={"S"}>Siembra</option>
                        <option value={"A"}>Otro</option>
                      </select>
                    </section>
                  </div>
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="btn-red-transparent"
                      type="button"
                      onClick={() => setOpen(false)}
                    >
                      Cerrar
                    </button>
                    <button className="btn-teal" type="submit">
                      Confirmar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
