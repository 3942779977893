import React from "react";

export default function FactureToPrintView(props) {
  const { list } = props;

  return (
    <table width="100%" className="mb-10">
      <thead>
        <tr className="bg-gray-100 p-1">
          <td className="font-bold">Item</td>
          <td className="font-bold">Cantidad de sacos</td>
          <td className="font-bold">Lbs</td>
          <td className="font-bold">Precio</td>
          <td className="font-bold">Precio Lbs</td>
          <td className="font-bold">Descuento</td>
          <td className="font-bold">Total</td>
        </tr>
      </thead>
      {list.map(
        ({ id, item_name, quantity, weight, price, weightPrice, discount }) => (
          <React.Fragment key={id}>
            <tbody>
              <tr className="h-10">
                <td>{item_name}</td>
                <td>{parseInt(quantity * 10) / 10}</td>
                <td>{parseInt(weight * 100) / 100}</td>
                <td>{parseInt(price)}</td>
                <td>{parseInt(weightPrice * 100) / 100}</td>
                <td>{discount} %</td>
                <td>{parseInt(quantity * price * (1 - discount / 100))}</td>
              </tr>
            </tbody>
          </React.Fragment>
        )
      )}
    </table>
  );
}
