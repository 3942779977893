import React, { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function FormDialog({ type, setMaj, maj }) {
  const [open, setOpen] = useState(false);
  const [especeList, setEspeceList] = useState([]);
  const [newData, setNewData] = useState({});

  useEffect(() => {
    var url = "/api/especes";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setEspeceList(response.especes);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleConfirm();
  };

  const handleConfirm = () => {
    var url = "/api/addInTable/" + type;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...newData }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        console.log(response);
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setOpen(false);
    setNewData({});
  };

  return (
    <div>
      <button className="btn-blue" type="button" onClick={() => setOpen(true)}>
        Añadir
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none ">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none mx-20">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2> Añadir</h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <form
                  className="mt-5 mx-5"
                  onSubmit={handleSubmit}
                  id="changeAliment"
                >
                  {/* Nouveau bassin, Especes, Pompes, TypeAliment  */}
                  {[
                    "Bassins",
                    "Especes",
                    "Pompes",
                    "TypeAliment",
                    "Aerateurs",
                  ].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Libelle</label>
                      <input
                        type="text"
                        name="name"
                        value={newData.libelle}
                        onChange={(e) =>
                          setNewData({ ...newData, libelle: e.target.value })
                        }
                        placeholder="Libelle"
                      />
                    </section>
                  ) : null}
                  {/* Nouveau bassin  */}
                  {["Bassins"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Surface</label>
                      <input
                        type="number"
                        name="name"
                        value={newData.surface}
                        onChange={(e) =>
                          setNewData({ ...newData, surface: e.target.value })
                        }
                      />
                    </section>
                  ) : null}
                  {["Bassins"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Profondeur</label>
                      <input
                        type="number"
                        name="name"
                        value={newData.profondeur}
                        onChange={(e) =>
                          setNewData({ ...newData, profondeur: e.target.value })
                        }
                      />
                    </section>
                  ) : null}
                  {/* Nouvelle espèce */}
                  {["Especes"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Espece</label>
                      <input
                        type="text"
                        name="name"
                        value={newData.espece}
                        onChange={(e) =>
                          setNewData({ ...newData, espece: e.target.value })
                        }
                      />
                    </section>
                  ) : null}
                  {["Especes"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Type</label>
                      <select
                        name="role"
                        value={newData.espece_id}
                        onChange={(e) =>
                          setNewData({ ...newData, type: e.target.value })
                        }
                      >
                        <option value={"carpe"}>Carpe</option>
                        <option value={"colossoma"}>Colossoma</option>
                        <option value={"tilapia"}>Tilapia</option>
                        <option value={"crevette"}>Crevettte</option>
                      </select>
                    </section>
                  ) : null}
                  {/* Nouvelle pompe */}
                  {["Pompes"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Débit</label>
                      <input
                        type="number"
                        name="name"
                        value={newData.debit}
                        onChange={(e) =>
                          setNewData({ ...newData, debit: e.target.value })
                        }
                      />
                    </section>
                  ) : null}
                  {/* Nouvelle mortalite */}
                  {["Mortalite"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Taux initial</label>
                      <input
                        type="number"
                        value={newData.taux_initial}
                        onChange={(e) =>
                          setNewData({
                            ...newData,
                            taux_initial: e.target.value,
                          })
                        }
                      />
                    </section>
                  ) : null}
                  {["Mortalite"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Taux hebdomadaire</label>
                      <input
                        type="number"
                        value={newData.taux_hebdomadaire}
                        onChange={(e) =>
                          setNewData({
                            ...newData,
                            taux_hebdomadaire: e.target.value,
                          })
                        }
                      />
                    </section>
                  ) : null}
                  {/* Nouvelle croissance */}
                  {["Croissance", "Mortalite"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Especie</label>
                      <select
                        name="role"
                        value={newData.espece_id}
                        onChange={(e) =>
                          setNewData({
                            ...newData,
                            espece_id: e.target.value,
                          })
                        }
                      >
                        {especeList.map((e, key) => {
                          return (
                            <option key={key} value={e[0]}>
                              {e[1]}
                            </option>
                          );
                        })}
                      </select>
                    </section>
                  ) : null}
                  {["Croissance"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Semaine</label>
                      <input
                        type="number"
                        value={newData.semaine}
                        onChange={(e) =>
                          setNewData({ ...newData, semaine: e.target.value })
                        }
                      />
                    </section>
                  ) : null}
                  {["Croissance"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Nombre par livre</label>
                      <input
                        type="number"
                        value={newData.nombre_par_livre}
                        onChange={(e) =>
                          setNewData({
                            ...newData,
                            nombre_par_livre: e.target.value,
                          })
                        }
                      />
                    </section>
                  ) : null}
                  {["Croissance"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>Pourcentage aliment</label>
                      <input
                        type="number"
                        value={newData.pourcentage_aliment}
                        onChange={(e) =>
                          setNewData({
                            ...newData,
                            pourcentage_aliment: e.target.value,
                          })
                        }
                      />
                    </section>
                  ) : null}
                  {["Aerateurs"].includes(type) ? (
                    <section className="flex flex-col">
                      <label>HP</label>
                      <input
                        type="number"
                        value={newData.pourcentage_aliment}
                        onChange={(e) =>
                          setNewData({
                            ...newData,
                            puissance: e.target.value,
                          })
                        }
                      />
                    </section>
                  ) : null}
                </form>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="btn-red-transparent"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Cerrar
                  </button>
                  <button
                    className="btn-teal"
                    type="button"
                    onClick={() => handleConfirm()}
                  >
                    Añadir
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
