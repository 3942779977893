import React, { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";
import TableLot from "./TableLot";
import TableTotal from "./TableTotal";
import Loading from "../Loading";

export default function AncienLots({ cycle_, resetCycle }) {
  const [cycle] = useState(cycle_.toString());
  const [lot, setLot] = useState("");
  const [comm, setComm] = useState("");
  const [lotsMenu, setLotsMenu] = useState([]);

  const [data, setData] = useState({ peches: [], semis: [], statistiques: [] });
  const [espece, setEspece] = useState("Tilapia");
  const [maj, setMaj] = useState(true);
  const [total, setTotal] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    var url = "/api/ancienLots/" + cycle;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setLotsMenu(response.lotsList);
        console.log(response.lotsList);
        setLot(response.lotsList[0][2]);
        setComm(response.lotsList[0][3]);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [cycle]);

  useEffect(() => {
    if (lot !== undefined && lot !== "") {
      var url = "/api/lotData/" + lot;
      authFetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((data) => {
              throw new Error(data.message);
            });
          }
        })
        .then((response) => {
          setEspece(response.espece ? response.espece[0] : "");
          setData({
            peches: response.peches,
            semis: response.semis,
            statistiques: response.statistiques,
          });
          setTotal(response.total);
          setIsLoading(false);
        })
        .catch((e) => toast.error(e.name + ": " + e.message));
    }
  }, [lot, maj]);

  const handleConfirm = () => {
    var url = "/api/alimentation";

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: lot,
        poids: total.alimentation,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div id="lot">
      <div className="bg-white shadow-md rounded-lg px-2 py-2 m-0">
        <div className="flex justify-between items-center mb-4 bg-teal-600 text-white rounded-t-md">
          <h1 className="my-5 mx-4 font-light text-6xl">{espece}</h1>
          <p className="mx-2">{comm}</p>
        </div>
        <div className="flex justify-between">
          <div className="">
            {lotsMenu.map((Lot, key) => (
              <button
                className={Lot[2] === 1 ? "btn-red mx-1" : "btn-blue mx-1"}
                key={key}
                onClick={() => setLot(Lot[2])}
              >
                {Lot[4]}
              </button>
            ))}
          </div>
          <div className="flex flex-row">
            <button className="btn-blue mx-1" onClick={() => resetCycle()}>
              Back
            </button>
          </div>
        </div>
        <div className="md:grid grid-cols-2">
          <section className="bg-gray-100  shadow-md rounded-lg px-1 py-2 m-1">
            <div className="flex justify-between border-b-2 mb-4">
              <h2 className="my-5">Semis</h2>
            </div>
            <div className="overflow-x-auto">
              <TableLot
                headers={[
                  "fecha",
                  "peso",
                  "cant/lb",
                  "gr/unidad",
                  "cantidad",
                  "comentario",
                ]}
                deleteUrl="/api/lotData/Semis"
                postUrl={"/api/Semis/" + lot}
                setMaj={setMaj}
                maj={maj}
                list={data.semis}
              />
            </div>
          </section>
          <section className="bg-gray-100 shadow-md rounded-lg px-1 py-2 m-1">
            <div className="flex justify-between border-b-2 mb-4">
              <h2 className="my-5">Statisticas</h2>
            </div>
            <div className="overflow-x-auto">
              <TableLot
                headers={[
                  "fecha",
                  "peso",
                  "cant/lb",
                  "gr/unidad",
                  "cantidad",
                  "comentario",
                ]}
                deleteUrl="/api/lotData/Statistiques"
                postUrl={"/api/stats/" + lot}
                setMaj={setMaj}
                maj={maj}
                list={data.statistiques}
              />
            </div>
          </section>
          <section className="bg-gray-100  shadow-md rounded-lg px-1 py-2 m-1 ">
            <div className="flex justify-between border-b-2 mb-4">
              <h2 className="my-5">Pescas</h2>
            </div>
            <div className="overflow-x-auto">
              <TableLot
                headers={[
                  "fecha",
                  "peso",
                  "cant/lb",
                  "gr/unidad",
                  "cantidad",
                  "comentario",
                  "destino",
                ]}
                deleteUrl="/api/lotData/Peches"
                postUrl={"/api/peches/" + lot}
                setMaj={setMaj}
                maj={maj}
                list={data.peches}
              />
            </div>
          </section>
          <section className="bg-gray-100  shadow-md rounded-lg px-1 py-2 m-1">
            <div className="flex justify-between border-b-2 mb-4">
              <h2 className="my-5">Total</h2>
            </div>
            <div className="overflow-x-auto">
              <TableTotal
                total={total}
                espece={espece}
                data={data}
                setTotal={setTotal}
                handleConfirm={handleConfirm}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
