import { useState } from "react";

export default function ShowAncien({ bassinsList }) {
  const [open, setOpen] = useState(false);
  const [bassin, setBassin] = useState("tous");
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");

  return (
    <div>
      <button className="btn-teal" type="button" onClick={() => setOpen(true)}>
        Ver ciclos anteriores
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2> Ver ciclos anteriores</h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <div className="md:grid grid-cols-4 gap-2 relative p-6">
                  <section className="flex flex-col">
                    <label>Estanque</label>
                    <select
                      className="m-0"
                      value={bassin}
                      onChange={(e) => setBassin(e.target.value)}
                    >
                      {bassinsList.map((Bassin, key) => {
                        return (
                          <option key={key} value={Bassin[0]}>
                            {Bassin[1]}
                          </option>
                        );
                      })}
                    </select>
                  </section>
                  <section className="flex flex-col">
                    <label>Fecha inicio</label>
                    <input
                      required
                      className="m-0"
                      type="date"
                      placeholder="Fecha"
                      value={dateDebut}
                      onChange={(e) => setDateDebut(e.target.value)}
                    />
                  </section>
                  <section className="flex flex-col">
                    <label>Fecha fin</label>
                    <input
                      required
                      className="m-0"
                      type="date"
                      placeholder="Fecha"
                      value={dateFin}
                      onChange={(e) => setDateFin(e.target.value)}
                    />
                  </section>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="btn-red-transparent"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Cerrar
                  </button>
                  <a
                    className="btn-teal"
                    href={
                      "/finca/ancienCycle/" +
                      bassin +
                      "/" +
                      dateDebut +
                      "/" +
                      dateFin
                    }
                  >
                    Confirmar
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
