import React, { useState, useEffect, useMemo, useRef } from "react";

import { authFetch } from "auth";
import SetDate from "components/Dialog/SetDate";
import { toast } from "react-toastify";

export default function AddAnalyseEau() {
  const [isLoading, setLoading] = useState(true);

  const [analyseEau, setAnalyseEau] = useState([]);
  const [analyseOxygene, setAnalyseOxygene] = useState([]);
  const [cyclesList, setCyclesList] = useState(undefined);
  const [selectedCycle, setSelectedCycle] = useState(undefined);
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [maj, setMaj] = useState(false);

  const temp17Ref = useRef();
  const temp4Ref = useRef();
  const phRef = useRef();
  const seRef = useRef();
  const oxygene0hRef = useRef();
  const oxygene4hRef = useRef();
  const oxygene17hRef = useRef();
  const oxygene22hRef = useRef();

  useEffect(() => {
    setLoading(true);
  }, [date]);

  useEffect(() => {
    let isCancelled = false;
    var url = "/api/analyseEau/" + date;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        if (!isCancelled) {
          setCyclesList(response.cycles.sort((a, b) => (a[0] > b[0] ? 1 : -1)));
          setAnalyseEau(response.analyseEau);
          setAnalyseOxygene(response.analyseOxygene);
          setLoading(false);
        }
      })
      .catch((e) => toast.error(e.name + ": " + e.message));

    return () => {
      isCancelled = true;
    };
  }, [date, maj]);

  const selectedInfo = useMemo(() => {
    if (selectedCycle && cyclesList) {
      const bassin = cyclesList[selectedCycle][0];
      const eau = analyseEau.filter((a) => a[1] === bassin)[0];
      const oxygene = analyseOxygene.filter((a) => a[0] === bassin);
      var ox = {};
      for (let i = 0; i < oxygene.length; i++) {
        const o = oxygene[i];
        ox[o[3]] = o[2];
      }
      if (eau) {
        return {
          ...ox,
          Bassin: bassin,
          Temp4: eau[3] !== null ? eau[3] : "",
          Temp17: eau[4] !== null ? eau[4] : "",
          PH: eau[5] !== null ? eau[5] : "",
          SE: eau[6] !== null ? eau[6] : "",
        };
      } else {
        return {
          ...ox,
          Bassin: bassin,
          Temp4: "",
          Temp17: "",
          PH: "",
          SE: "",
        };
      }
    }
  }, [selectedCycle, analyseEau, analyseOxygene, cyclesList]);

  useEffect(() => {
    if (selectedInfo) {
      temp17Ref.current.value = selectedInfo.Temp17;
      temp4Ref.current.value = selectedInfo.Temp4;
      phRef.current.value = selectedInfo.PH;
      seRef.current.value = selectedInfo.SE;
      oxygene0hRef.current.value = selectedInfo["0"];
      oxygene4hRef.current.value = selectedInfo["4"];
      oxygene17hRef.current.value = selectedInfo["17"];
      oxygene22hRef.current.value = selectedInfo["22"];
    }
  }, [selectedInfo]);

  const handleDateChange = (newDate) => {
    if (newDate.length === 10) {
      setDate(newDate);
      setLoading(true);
    }
  };

  const handleConfirm = () => {
    const currentValue = {
      Temp4: temp4Ref.current.value,
      Temp17: temp17Ref.current.value,
      PH: phRef.current.value,
      SE: seRef.current.value,
      oxygene0h: oxygene0hRef.current.value,
      oxygene4h: oxygene4hRef.current.value,
      oxygene17h: oxygene17hRef.current.value,
      oxygene22h: oxygene22hRef.current.value,
    };
    if (
      parseFloat(currentValue.oxygene17h) > 20 ||
      parseFloat(currentValue.oxygene22h) > 20 ||
      parseFloat(currentValue.oxygene0h) > 20 ||
      parseFloat(currentValue.oxygene4h) > 20
    ) {
      toast.warn("Valor de oxígeno demasiado alto (mas de 20)");
    } else if (
      (currentValue.Temp4 > 40 || currentValue.Temp4 < 15) &&
      currentValue.Temp4 !== "" &&
      currentValue.Temp4 !== null
    ) {
      toast.warn("Valor de temperatura 4a.m superior a 40 o inferior a 15");
    } else if (
      (currentValue.Temp17 > 40 || currentValue.Temp17 < 15) &&
      currentValue.Temp17 !== "" &&
      currentValue.Temp17 !== null
    ) {
      toast.warn("Valor de temperatura 5p.m superior a 40 o inferior a 15");
    } else if (
      (currentValue.PH > 14 || currentValue.PH < 5) &&
      currentValue.PH !== "" &&
      currentValue.PH !== null
    ) {
      toast.warn("Valor de PH superior a 14 o inferior a 5");
    } else if (
      currentValue.SE <= 0 &&
      currentValue.SE !== "" &&
      currentValue.SE !== null
    ) {
      toast.warn("Valor de SE no puede ser inferior a 0");
    } else {
      let temp = cyclesList;
      temp[selectedCycle] = [temp[selectedCycle][0], true];
      setCyclesList(temp);
      var url = "/api/analyseEau";
      var requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...currentValue,
          bassin: selectedInfo.Bassin,
          date: date,
        }),
      };
      authFetch(url, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((data) => {
              throw new Error(data.message);
            });
          }
        })
        .then((response) => {
          toast.success(response.message);
        })
        .catch((e) => toast.error(e.name + ": " + e.message));
      url = "/api/analyseOx";
      requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: {
            0: currentValue.oxygene0h,
            4: currentValue.oxygene4h,
            17: currentValue.oxygene17h,
            22: currentValue.oxygene22h,
          },
          date: date,
          bassin: selectedInfo.Bassin,
        }),
      };
      authFetch(url, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((data) => {
              throw new Error(data.message);
            });
          }
        })
        .then((response) => {
          toast.success(response.message);
          setMaj(~maj);
        })
        .catch((e) => toast.error(e.name + ": " + e.message));
    }
  };

  const changeDate = (d) => {
    var result = new Date(date + "T12:00:00");
    result.setDate(result.getDate() + d);
    setDate(result.toISOString().substring(0, 10));
    setSelectedCycle(undefined);
  };

  return (
    <div>
      <div className="flex flex-row justify-center min-w-full">
        <button className="btn-teal" onClick={() => changeDate(-1)}>
          {"<<"}
        </button>
        <SetDate date={date} handleDateChange={handleDateChange} />
        <button className="btn-teal" onClick={() => changeDate(1)}>
          {">>"}
        </button>
      </div>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2 grid grid-cols-3">
        <div className="flex flex-col col-span-1 overflow-y-scroll overflow-x-hidden max-h-4/5">
          {cyclesList &&
            cyclesList.map((c, key) => (
              <div id={key + 1000} className="min-w-full h-16 pb-1">
                <button
                  className={
                    !c[1]
                      ? "font-bold text-xl bg-sky-600 text-white min-w-full h-14  rounded-md hover:bg-sky-700 active:bg-sky-800 transition-all duration-300"
                      : "font-bold text-xl bg-green-600 text-white min-w-full h-14  rounded-md hover:bg-green-700 active:bg-green-800 transition-all duration-300"
                  }
                  id={key}
                  onClick={(e) => {
                    e.target.id === ""
                      ? setSelectedCycle(e.target.offsetParent.id)
                      : setSelectedCycle(e.target.id);
                  }}
                >
                  {c[0]}
                </button>
              </div>
            ))}
        </div>
        <div className="col-span-2 ">
          {selectedCycle ? (
            <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
              <div className="flex justify-center mb-4 bg-teal-600 text-white rounded-t-md">
                <h1 className="my-5 font-light text-6xl">
                  {selectedInfo.Bassin}
                </h1>
              </div>
              <div className="md:grid xl:grid-cols-4 grid-cols-2  gap-6 ">
                <div className="flex flex-col">
                  <label className="text-gray-500 font-medium text-base">
                    Temperatura 5p.m
                  </label>
                  <input
                    className="m-0 borderw"
                    type="number"
                    ref={temp17Ref}
                  />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-500 font-medium text-base">
                    Temperatura 4a.m
                  </label>
                  <input className="m-0" type="number" ref={temp4Ref} />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-500 font-medium text-base">
                    PH
                  </label>
                  <input className="m-0" type="number" ref={phRef} />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-500 font-medium text-base">
                    SE
                  </label>
                  <input className="m-0" type="number" ref={seRef} />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-500 font-medium text-base">
                    Oxigeno 12 a.m
                  </label>
                  <input className="m-0" type="number" ref={oxygene0hRef} />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-500 font-medium text-base">
                    Oxigeno 4 a.m
                  </label>
                  <input className="m-0" type="number" ref={oxygene4hRef} />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-500 font-medium text-base">
                    Oxigeno 5 p.m
                  </label>
                  <input className="m-0" type="number" ref={oxygene17hRef} />
                </div>
                <div className="flex flex-col">
                  <label className="text-gray-500 font-medium text-base">
                    Oxigeno 10 p.m
                  </label>
                  <input className="m-0" type="number" ref={oxygene22hRef} />
                </div>
                <div className="flex items-center justify-center">
                  <button onClick={handleConfirm} className="btn-teal">
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          ) : isLoading ? (
            <div className="flex justify-center items-center min-h-full">
              <div className="loader"></div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
