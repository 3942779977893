// @material-ui/icons
import {
  BsTable,
  BsCardList,
  BsGraphUp,
  BsWater,
  BsSnow,
  BsCurrencyDollar,
} from "react-icons/bs";
import { AiOutlineAlert, AiOutlineHome } from "react-icons/ai";
import { IoRestaurantOutline } from "react-icons/io5";
import { GiWaterfall } from "react-icons/gi";
// core components/views for Admin layout
import Table from "views/Table";
import Cycles from "views/Cycles";
import AnalysesEau from "views/AnalysesEau";
import AddAnalysesEau from "views/AddAnalyseEau";
import AddAeration from "views/AddAeration";
import Alimentation from "views/Alimentation";
import AlimentationAdmin from "views/AlimentationAdmin";
import AddChangementEau from "views/AddChangementEau";
import VenteCamarones from "views/VenteCamarones";
import VentePescados from "views/VentePescados";
import VenteAliment from "views/VenteAliment";
import Stocks from "views/Stocks";
import Alertes from "views/Alertes";

const dashboardRoutes = [
  {
    path: "/table",
    name: "Examinar las tablas",
    icon: BsTable,
    component: Table,
    layout: "/finca",
    roles: [1, 2, 3],
  },
  {
    path: "/cycles",
    name: "Gestión de los ciclos",
    icon: BsCardList,
    component: Cycles,
    layout: "/finca",
    roles: [1, 2, 3],
  },
  {
    path: "/analyseEau",
    name: "Análisis de agua",
    icon: BsGraphUp,
    component: AnalysesEau,
    layout: "/finca",
    roles: [1, 2, 3],
  },
  {
    path: "/alertes",
    name: "Alertas",
    icon: AiOutlineAlert,
    component: Alertes,
    layout: "/finca",
    roles: [1, 2, 3],
  },
  {
    path: "/addAnalyseEau",
    name: "Añadir análisis de agua",
    icon: BsWater,
    component: AddAnalysesEau,
    layout: "/finca",
    roles: [1, 2, 3, 4, 5],
  },
  {
    path: "/alimentation",
    name: "Alimentación diaria",
    icon: IoRestaurantOutline,
    component: Alimentation,
    layout: "/finca",
    roles: [1, 4, 5],
  },
  {
    path: "/alimentationAdmin",
    name: "Alimentación",
    icon: IoRestaurantOutline,
    component: AlimentationAdmin,
    layout: "/finca",
    roles: [1, 2, 3],
  },
  {
    path: "/changementEau",
    name: "Añadir un recambio de agua",
    icon: GiWaterfall,
    component: AddChangementEau,
    layout: "/finca",
    roles: [1, 2, 3],
  },
  {
    path: "/aeration",
    name: "Añadir uso de aireador",
    icon: BsSnow,
    component: AddAeration,
    layout: "/finca",
    roles: [1, 2, 3],
  },
  {
    path: "/ventesCamarones",
    name: "Venta de Camarones",
    icon: BsCurrencyDollar,
    component: VenteCamarones,
    layout: "/finca",
    roles: [1, 2, 3],
  },
  {
    path: "/ventesPescados",
    name: "Venta de Pescados",
    icon: BsCurrencyDollar,
    component: VentePescados,
    layout: "/finca",
    roles: [1, 2, 3],
  },
  {
    path: "/mouvement",
    name: "Venta de alimento",
    icon: BsCurrencyDollar,
    component: VenteAliment,
    layout: "/finca",
    roles: [1, 2, 3],
  },
  {
    path: "/stocks",
    name: "Almacen",
    icon: AiOutlineHome,
    component: Stocks,
    layout: "/finca",
    roles: [1, 2, 3],
  },
];

export default dashboardRoutes;
