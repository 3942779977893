import RowEditable from "components/RowEditable";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function TableVentesPoisson({ list, maj, setMaj }) {
  const handleEdit = (edited, id) => {
    var data = {
      id: id,
      fecha: edited[0].value,
      peso: edited[1].value,
    };
    var url = "/api/ventes/poissons";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(~maj);
  };

  return (
    <>
      {/* Table items */}
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Fecha
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Peso
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Total pesca
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              % perdida
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(({ id, fecha, peso, total, perdida }, key) => {
            const row = [
              {
                name: "fecha",
                value: fecha,
                editable: true,
                type: "input-date",
              },
              {
                name: "peso",
                value: peso,
                editable: true,
                type: "input-number",
              },
              {
                name: "total",
                value: total,
                editable: false,
              },
              {
                name: "perdida",
                value: perdida,
                editable: false,
              },
            ];
            return (
              <RowEditable
                key={id}
                id={id}
                row={row}
                color={key % 2 === 1 ? "teal-50" : "white"}
                handleEdit={handleEdit}
                removeDelete={true}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
}
