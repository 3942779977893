import React, { useState } from "react";
import AnalysesEau from "views/AnalysesEau";

export default function FormDialog({ bassin }) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button className="btn-teal" type="button" onClick={() => setOpen(true)}>
        Ver analisis
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto   w-2/3">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="overflow-scroll max-h-4/5">
                  <AnalysesEau bassinDefault={bassin} inDialog={true} />
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="btn-red-transparent"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
