import React, { useState } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function AlimentationForm({
  cycle,
  cyclesList,
  setCycle,
  setCyclesList,
  poidsAM,
  setPoidsAM,
  poidsPM,
  setPoidsPM,
  alimentList,
  setMaj,
  date,
  maj,
}) {
  const [open, setOpen] = useState(false);
  const [change, setChange] = useState(false);

  const handleSubmit = (e) => {
    if (e !== undefined) {
      e.preventDefault();
    }
    let temp = cyclesList;
    temp[cycle.id] = {
      ...temp[cycle.id],
      ali: true,
      Poids: poidsAM + poidsPM,
      Poids_pm: poidsPM,
      maj: true,
      Type_Aliment: cycle.Type_Aliment,
    };
    setCyclesList(temp);
    var url = "/api/alimentation";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        bassin: cycle.Bassin,
        poids: poidsAM + poidsPM,
        poids_pm: poidsPM,
        date: date,
        aliment: cycle.Type_Aliment,
        change: change,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(~maj);
    setOpen(false);
    setCycle(undefined);
  };

  const handleOpen = () => {
    if (cyclesList[cycle.id].Type_Aliment !== cycle.Type_Aliment) {
      setOpen(true);
    } else {
      handleSubmit(undefined);
    }
  };

  return (
    <form className="xl:grid grid-cols-5 gap-2" onSubmit={handleSubmit}>
      <section className="flex flex-col">
        <h4 style={{ textAlign: "center" }}>
          Peso teorico: {cycle["poids aliment a donner"]}
        </h4>
      </section>
      <section className="flex flex-col">
        <label>Peso por la mañana</label>
        <input
          className="m-0"
          type="number"
          placeholder="Peso por la mañana"
          value={poidsAM}
          onChange={(e) =>
            setPoidsAM(e.target.value === "" ? "" : parseInt(e.target.value))
          }
        />
      </section>
      <section className="flex flex-col">
        <label>Peso por la tarde</label>
        <input
          className="m-0"
          type="number"
          placeholder="Peso por la tarde"
          value={poidsPM}
          onChange={(e) =>
            setPoidsPM(e.target.value === "" ? "" : parseInt(e.target.value))
          }
        />
      </section>
      <section className="flex flex-col">
        <label>Alimento</label>
        <select
          className="m-0"
          name="role"
          value={cycle.Type_Aliment}
          onChange={(e) => setCycle({ ...cycle, Type_Aliment: e.target.value })}
        >
          {alimentList.map((a, key) => {
            return (
              <option key={key} value={a[0]}>
                {a[1]}
              </option>
            );
          })}
        </select>
      </section>
      <div className="flex mx-2 justify-center items-center">
        <button
          className="btn-teal h-12"
          type="button"
          onClick={() => handleOpen()}
        >
          Confirmar
        </button>
        {open ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                    <h2>Cambio de alimento</h2>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  <div className="md:grid grid-cols-1 gap-2 relative p-6">
                    <section className="flex flex-col">
                      <label className="text-gray-700 text-md">
                        Modificar el alimento para los dias siguientes
                        <input
                          className="my-0 mx-3"
                          type="checkbox"
                          placeholder="Fecha"
                          checked={change}
                          onChange={(e) => setChange(e.target.checked)}
                        />
                      </label>
                    </section>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="btn-red-transparent"
                      type="button"
                      onClick={() => setOpen(false)}
                    >
                      Cerrar
                    </button>
                    <button className="btn-teal" type="submit">
                      Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
    </form>
  );
}
