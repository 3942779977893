import { useMemo } from "react";

export default function TableTotal({
  total,
  espece,
  data,
  setTotal,
  handleConfirm,
}) {
  const grPoisson = useMemo(() => {
    var semis =
      espece.substring(0, 10) === "Postlarves" ||
      espece.substring(0, 9) === "Juvéniles"
        ? Math.round((total.totalSemis[1] / total.totalSemis[0]) * 10) / 10
        : Math.round(((total.totalSemis[0] * 454) / total.totalSemis[1]) * 10) /
          10;
    if (!semis) {
      semis = 0;
    }

    var peches =
      espece.substring(0, 10) === "Postlarves" ||
      espece.substring(0, 9) === "Juvéniles"
        ? Math.round((total.totalPeches[1] / total.totalPeches[0]) * 10) / 10
        : Math.round(
            ((total.totalPeches[0] * 454) / total.totalPeches[1]) * 10
          ) / 10;
    if (!peches) {
      peches = 0;
    }
    var teo =
      total.totalActuel[0] <= 0 || total.totalActuel[1] <= 0
        ? 0
        : espece.substring(0, 10) === "Postlarves" ||
          espece.substring(0, 9) === "Juvéniles"
        ? Math.round((total.totalActuel[0] / total.totalActuel[1]) * 10) / 10
        : Math.round(
            ((total.totalActuel[1] * 454) / total.totalActuel[0]) * 10
          ) / 10;
    if (!teo) {
      teo = 0;
    }

    var stat =
      espece.substring(0, 10) === "Postlarves" ||
      espece.substring(0, 9) === "Juvéniles"
        ? Math.round(total.nbrLivre * 10) / 10
        : total.grUnidad;
    if (!stat) {
      stat = 0;
    }
    return { semis: semis, peches: peches, teo: teo, stat: stat };
  }, [total, espece]);

  if (!total) {
    return <div>loading...</div>;
  }

  return (
    <table aria-label="total" className="w-full">
      <thead className="bg-teal-500 rounded">
        <tr>
          <th className="text-sm font-medium text-white px-6 py-4 text-left"></th>
          <th className="text-sm font-medium text-white px-6 py-4 text-left">
            animales
          </th>
          <th className="text-sm font-medium text-white px-6 py-4 text-left">
            libras
          </th>
          <th className="text-sm font-medium text-white px-6 py-4 text-left">
            {espece.substring(0, 10) === "Postlarves" ||
            espece.substring(0, 9) === "Juvéniles"
              ? "animales por libras"
              : "grpoisson"}
          </th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr className="bg-white border-b hover:bg-gray-100 text-center">
          <td className=" text-xl text-gray-900 font-normal px-2 py-2 whitespace-nowrap ">
            Siembra
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {Math.round(total.totalSemis[1])}
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {Math.round(total.totalSemis[0] * 10) / 10}
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {grPoisson.semis}
          </td>
          <td />
        </tr>
        <tr className="bg-teal-50 border-b hover:bg-gray-100 text-center">
          <td className=" text-xl text-gray-900 font-normal px-2 py-2 whitespace-nowrap ">
            Pesca
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {total.totalPeches[1]}
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {total.totalPeches[0]}
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {grPoisson.peches}
          </td>
          <td />
        </tr>
        <tr className="bg-white border-b hover:bg-gray-100 text-center">
          <td className=" text-xl text-gray-900 font-normal px-2 py-2 whitespace-nowrap ">
            Actual teo
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {total.totalActuel[0] < 0 ? 0 : total.totalActuel[0]}
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {total.totalActuel[1] < 0 ? 0 : total.totalActuel[1]}
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {grPoisson.teo}
          </td>
          <td />
        </tr>
        <tr className="bg-teal-50 border-b hover:bg-gray-100 text-center">
          <td className=" text-xl text-gray-900 font-normal px-2 py-2 whitespace-nowrap ">
            Actual stat
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {total.totalPoissonStat
              ? Math.round(total.totalPoissonStat)
              : total.totalPoissonStat === 0
              ? 0
              : null}
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {total.totalPoissonStat
              ? Math.round(total.poidsTotalStat)
              : total.totalPoissonStat === 0
              ? 0
              : null}
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {grPoisson.stat}
          </td>
          <td />
        </tr>
        <tr className="bg-white border-b hover:bg-gray-100 text-center">
          <td
            className=" text-xl text-gray-900 font-light px-2 py-2 whitespace-nowrap text-center"
            colSpan={2}
            align="right"
          >
            Mort teo / stat
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {Math.round(total.totalActuel[3] * 1000) / 10} %
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {total.mortaliteStat
              ? Math.round(total.mortaliteStat * 1000) / 10
              : "_"}{" "}
            %
          </td>
          <td />
        </tr>
        <tr className="bg-teal-50 border-b hover:bg-gray-100 text-center">
          <td
            className=" text-xl text-gray-900 font-light px-2 py-2 whitespace-nowrap text-center"
            colSpan={2}
            align="right"
          >
            Alimentacion teo / stat
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {total.totalActuel[4]} lbs{" "}
            {Math.round(total.totalActuel[5] * 10000) / 100} %
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {total.alimentationstat
              ? Math.round(total.alimentationstat * 10) / 10
              : "_"}{" "}
            lbs{" "}
            {total.alimentationstatpourc
              ? Math.round(total.alimentationstatpourc * 10000) / 100
              : "_"}{" "}
            %
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            <input
              className="m-0"
              type="text"
              value={total.alimentation ? total.alimentation : ""}
              onChange={(e) =>
                setTotal({
                  ...total,
                  alimentation: e.target.value,
                })
              }
            />
          </td>
        </tr>
        <tr className="bg-white border-b hover:bg-gray-100 text-center">
          <td
            className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap "
            colSpan={2}
          />
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            Tiempo
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            {total.totalActuel[2]} meses
          </td>
          <td className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap ">
            <button className="btn-teal" onClick={handleConfirm}>
              V
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
