import RowEditable from "components/RowEditable";
import { authFetch } from "auth";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

export default function ShowTable({ list, maj, setMaj, headers, table }) {
  const handleEdit = (edited, id) => {
    var data = {};
    edited.forEach((e) => {
      data[e.name] = e.value;
    });
    var url = "/api/table/" + table;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(~maj);
  };

  return (
    <>
      {/* Table items */}
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            {headers.map((h, key) => {
              return (
                <th
                  key={key}
                  className="text-sm font-medium text-white px-6 py-4 text-left"
                >
                  {h}
                </th>
              );
            })}
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map((l, key) => {
            const row = headers.map((h) => {
              var type =
                typeof l[h] === "number" ? "input-number" : "input-text";
              return {
                name: h,
                value: l[h],
                editable: true,
                type: type,
              };
            });
            return (
              <RowEditable
                key={uuidv4()}
                id={l.id}
                row={row}
                color={key % 2 === 1 ? "teal-50" : "white"}
                handleEdit={handleEdit}
                removeDelete={true}
              />
            );
          })}
        </tbody>
      </table>
    </>
  );
}
