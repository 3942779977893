import { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";
import TableChangementEau from "./TableChangementEau";
import Loading from "components/Loading";

const getLastMonth = (date = new Date()) => {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 30);

  return previous;
};

export default function ShowChangementEau({
  defaultBassin,
  bassinsList,
  pompesList,
  maj,
  setMaj,
}) {
  const [tableData, setTableData] = useState([]);
  const [dates, setDates] = useState({
    first: getLastMonth().toISOString().substring(0, 10),
    second: new Date().toISOString().substring(0, 10),
  });
  const [isLoading, setIsLoading] = useState(true);
  const [bassin, setBassin] = useState(defaultBassin ? defaultBassin : "Todos");

  useEffect(() => {
    var url = "/api/pompes/" + dates.first + "/" + dates.second + "/" + bassin;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setTableData(response.changementEau);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [dates, maj, bassin]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
      <div className="flex justify-between border-b-2 mb-4">
        <h2 className="my-5">Ver cambio de agua</h2>
        <div className="flex flex-row">
          <section className="flex flex-col mt-2 justify-center mx-2">
            <label>Estanque</label>
            <select
              className="m-0 h-12"
              name="role"
              value={bassin}
              onChange={(e) => setBassin(e.target.value)}
            >
              <option key={201} value={"Todos"}>
                Todos
              </option>
              {bassinsList.map((Bassin, key) => (
                <option key={key} value={Bassin[1]}>
                  {Bassin[1]}
                </option>
              ))}
            </select>
          </section>
          <section className="flex flex-col mt-2 justify-center mx-2">
            <label>Fecha inicio</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha"
              value={dates.first}
              onChange={(e) =>
                setDates({
                  ...dates,
                  first: e.target.value,
                })
              }
            />
          </section>
          <section className="flex flex-col mt-2 justify-center">
            <label>Fecha fin</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha"
              value={dates.second}
              onChange={(e) =>
                setDates({
                  ...dates,
                  second: e.target.value,
                })
              }
            />
          </section>
        </div>
      </div>
      <TableChangementEau
        list={tableData}
        setList={setTableData}
        bassinsList={bassinsList}
        pompesList={pompesList}
        maj={maj}
        setMaj={setMaj}
      />
    </div>
  );
}
