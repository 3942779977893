import { useState, useEffect } from "react";
import { authFetch } from "auth";
import AddInventaireDialog from "../components/Stock/AddInventaireDialog";
import AddEntreAlimentDialog from "../components/Stock/AddEntreAlimentDialog";
import ShowVentes from "components/Stock/ShowVentes";
import { toast } from "react-toastify";
import StockTable from "components/Stock/StockTable";
import Loading from "components/Loading";
import AddAlimentDialog from "components/Dialog/AddAlimentDialog";

const getOldDate = () => {
  var date = new Date();
  date.setDate(date.getDate() - 30);
  return date.toISOString().substring(0, 10);
};

export default function Stocks() {
  const [isLoading, setIsLoading] = useState(true);
  const [stocks, setStocks] = useState([]);
  const [alimentList, setAlimentList] = useState([]);
  const [filtre, setFiltre] = useState(2);
  const [dateDebut, setDateDebut] = useState(getOldDate());
  const [maj, setMaj] = useState(true);

  useEffect(() => {
    const url = "/api/stocks/" + filtre + "/" + dateDebut;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setStocks(response.stocks);
        setAlimentList(response.alimentList);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [maj, filtre, dateDebut]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2 overflow-x-auto">
      <div className="flex justify-between ">
        <h2 className="my-5">Almacen</h2>
        <div className="flex flex-row">
          <section className="flex flex-col mt-2 justify-center mx-2">
            <label>Fecha inicio</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha dd/MM/yyyy"
              value={dateDebut}
              onChange={(e) => setDateDebut(e.target.value)}
            />
          </section>
          <section className="flex flex-col mt-2 justify-center">
            <label>Filtrar por alimento</label>
            <select
              className="m-0"
              name="role"
              value={filtre}
              onChange={(e) => setFiltre(e.target.value)}
              placeholder="Cliente"
            >
              {alimentList.map((aliment, key) => {
                return (
                  <option key={key} value={aliment[0]}>
                    {aliment[1]}
                  </option>
                );
              })}
            </select>
          </section>
        </div>
      </div>
      <div className="flex flex-col border-b-2 mb-4">
        <div className="flex flex-row items-center space-x-2 p-2 justify-center">
          <AddEntreAlimentDialog
            alimentsList={alimentList}
            maj={maj}
            setMaj={setMaj}
          />
          <AddAlimentDialog maj={maj} setMaj={setMaj} />
          <AddInventaireDialog
            alimentsList={alimentList}
            maj={maj}
            setMaj={setMaj}
          />
          <ShowVentes
            maj={maj}
            setMaj={setMaj}
            typeAlimentList={alimentList}
            defaultDate={dateDebut}
            defaultFiltre={filtre}
          />
        </div>
      </div>
      <StockTable maj={maj} setMaj={setMaj} list={stocks} />
    </div>
  );
}
