import React, { useState, useEffect } from "react";
import { authFetch } from "auth";
import "assets/css/App.css";
import { logout } from "auth";
import AddUser from "components/Profile/AddUser";
import { toast } from "react-toastify";
import ChangeUser from "components/Profile/ChangeUser";
import UsersList from "components/Profile/UsersList";
import Loading from "components/Loading";

const roleTrad = {
  admin: "Admin",
  boss: "Boss",
  assistant: "Asistante",
  analyse: "Analisis",
  alimentation: "Alimentacion",
  legacy: "Legacy",
};

export default function Profile() {
  const [maj, setMaj] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    authFetch("/api/roleList")
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        console.log(response.roleList);
        setRoleList(response.roleList);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="md:grid grid-cols-2 ">
        <UsersList
          majTop={maj}
          setMajTop={setMaj}
          roleList={roleList}
          roleTrad={roleTrad}
        />
        <div className="md:grid grid-rows-2 ">
          <ChangeUser
            majTop={maj}
            setMajTop={setMaj}
            roleList={roleList}
            roleTrad={roleTrad}
          />
          <AddUser
            majTop={maj}
            setMajTop={setMaj}
            roleList={roleList}
            roleTrad={roleTrad}
          />
        </div>
      </div>
      <div className="flex justify-evenly">
        <button
          onClick={logout}
          className="mb-5 bg-red-700 text-white font-bold py-2 px-8 rounded shadow border-2 border-red-700 hover:bg-red-600 transition-all duration-300"
        >
          Logout
        </button>
      </div>
    </div>
  );
}
