import { useState, useEffect } from "react";
import { authFetch } from "auth";
import DeleteClientDialog from "components/Client/DeleteClientDialog";
import { toast } from "react-toastify";
import ClientTable from "./ClientTable";

export default function Clients({ clientsList, maj, setMaj }) {
  const [deleteClientVisible, setDeleteClientVisible] = useState(false);
  const [client, setClient] = useState({
    id: clientsList[0][0],
    libelle: clientsList[0][1],
  });
  const [inf, setInf] = useState({
    nom: clientsList[0][1],
    adresse: "",
    cedula: "",
    tel: "",
  });

  const [tableData, setTableData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [majDelete, setMajDelete] = useState(false);

  useEffect(() => {
    setClient({ id: clientsList[0][0], libelle: clientsList[0][1] });
    setMaj(~maj);
  }, [majDelete]);

  useEffect(() => {
    const url = "/api/clients/" + client.id;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setInf({
          nom: clientsList.filter((c) => c[0] === client.id)[0][1],
          adresse: response.adresse === null ? "" : response.adresse,
          cedula: response.cedula === null ? "" : response.cedula,
          tel: response.tel === null ? "" : response.tel,
        });
        setTableData(response.achats);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [client, maj]);

  const handleClientChange = (id) => {
    const newC = clientsList.filter((a) => a[0] === parseInt(id))[0];
    setClient({ id: newC[0], libelle: newC[1] });
  };

  const handleConfirm = (cl, ms) => {
    const index = clientsList.findIndex((c) => {
      return c[0] === client.id;
    });
    clientsList[index][1] = inf.nom;
    var url = "/api/clients";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...client,
        ...inf,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        if (response.error === "true") {
          toast.warn(response.message);
        } else {
          toast.success(response.message);
        }
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-full">
        <div className="loader"></div>
      </div>
    );
  }

  return (
    <div>
      <div className="md:grid grid-cols-7 gap-2">
        <section className="flex flex-col">
          <label>Nombre</label>
          <select
            name="role"
            value={client.id}
            onChange={(e) => handleClientChange(e.target.value)}
            placeholder="Cliente"
          >
            {clientsList.map((c, key) => {
              return (
                <option key={key} value={c[0]}>
                  {c[1]}
                </option>
              );
            })}
          </select>
        </section>
        <section className="flex flex-col">
          <label>Nombre</label>
          <input
            type="text"
            name="name"
            value={inf.nom}
            onChange={(e) =>
              setInf({
                ...inf,
                nom: e.target.value,
              })
            }
            placeholder="Nombre"
          />
        </section>
        <section className="flex flex-col">
          <label>Direcion</label>
          <input
            type="text"
            name="adress"
            value={inf.adresse}
            onChange={(e) =>
              setInf({
                ...inf,
                adresse: e.target.value,
              })
            }
            placeholder="Direcion"
          />
        </section>
        <section className="flex flex-col">
          <label>Cedula</label>
          <input
            type="text"
            name="cedula"
            value={inf.cedula}
            onChange={(e) =>
              setInf({
                ...inf,
                cedula: e.target.value,
              })
            }
            placeholder="Cedula"
          />
        </section>
        <section className="flex flex-col">
          <label>Celular</label>
          <input
            type="text"
            name="celular"
            value={inf.tel}
            onChange={(e) =>
              setInf({
                ...inf,
                tel: e.target.value,
              })
            }
            placeholder="Celular"
          />
        </section>
        <button
          onClick={handleConfirm}
          className="btn-teal h-12 self-center mb-5 mt-2 "
        >
          Cambiar
        </button>
        <button
          onClick={() => setDeleteClientVisible(true)}
          className="btn-teal h-12 self-center  mb-5 mt-2"
        >
          Borrar el cliente
        </button>
        {deleteClientVisible ? (
          <DeleteClientDialog
            maj={majDelete}
            setMaj={setMajDelete}
            id={client.id}
            setDeleteClientVisible={setDeleteClientVisible}
          />
        ) : null}
      </div>
      <div>
        <ClientTable
          maj={maj}
          setMaj={setMaj}
          list={tableData}
          setList={setTableData}
        />
      </div>
    </div>
  );
}
