import React, { useState, useEffect } from "react";
import { authFetch } from "auth";
import AddSemisDialog from "./AddSemisDialog";
import AddPechesDialog from "./AddPechesDialog";
import AddStatsDialog from "./AddStatsDialog";
import AddLotDialog from "./AddLotDialog";
import VoirAnalyse from "../Dialog/VoirAnalyse";
import VoirChangementEau from "../Dialog/VoirChangementEau";
import { toast } from "react-toastify";
import TableLot from "./TableLot";
import TableTotal from "./TableTotal";
import Loading from "components/Loading";
import NoLots from "./NoLots";

export default function Lots({ cycle_, resetCycle }) {
  const [cycle, setCycle] = useState(cycle_.toString());
  const [lot, setLot] = useState(undefined);
  const [comm, setComm] = useState("");
  const [lotsList, setLotsList] = useState([]);
  const [lotsMenu, setLotsMenu] = useState([]);

  const [data, setData] = useState({ peches: [], semis: [], statistiques: [] });
  const [espece, setEspece] = useState("Tilapia");
  const [maj, setMaj] = useState(true);
  const [majLots, setMajLots] = useState(true);
  const [total, setTotal] = useState(undefined);
  const [isLoading, setisLoading] = useState(true);
  const [isLoadingInitial, setisLoadingInitial] = useState(true);

  useEffect(() => {
    console.log("lot", lot);
  }, [lot]);

  useEffect(() => {
    var url = "/api/lots";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setLotsList(response.lotsList);
        setisLoadingInitial(false);
        setLotsMenu([]);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [cycle, majLots]);

  useEffect(() => {
    let temp = [];
    let found = false;
    for (const lots of lotsList) {
      if (lots[1].toString() === cycle.toString()) {
        found = true;
        if (lot === "") {
          setLot(lots[2]);
          setComm(lots[3]);
        } else if (lots[2] === lot) {
          setLot(lots[2]);
          setComm(lots[3]);
        }
        temp = [[lots[2], lots[4], lots[5]], ...temp];
      }
    }
    if (!found) {
      setLot("");
      setComm("");
    }
    setLotsMenu(temp);
  }, [lotsList, cycle, lot]);

  useEffect(() => {
    if (lot !== undefined && lot !== "") {
      var url = "/api/lotData/" + lot;
      authFetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((data) => {
              throw new Error(data.message);
            });
          }
        })
        .then((response) => {
          if (response.message) {
            toast.error(response.message);
          } else {
            setEspece(response.espece ? response.espece[0] : "");
            setData({
              peches: response.peches,
              semis: response.semis,
              statistiques: response.statistiques,
            });
            setTotal(response.total);
            setisLoading(false);
          }
        })
        .catch((e) => toast.error(e.name + ": " + e.message));
    }
  }, [lot, maj]);

  const endLot = () => {
    var url = "/api/lots";
    var mortalite =
      ((total.totalSemis[1] - total.totalPeches[1]) * total.totalActuel[3]) /
      (total.totalSemis[1] - total.totalPeches[1] - total.totalActuel[0]);

    const dateToday = new Date().toISOString().substring(0, 10);
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: lot,
        quantitelb:
          total.totalActuel[1] === 0
            ? 0
            : total.totalActuel[0] / total.totalActuel[1],
        mortalite: mortalite,
        date:
          data.peches.length > 0
            ? data.peches[data.peches.length - 1].fecha
            : dateToday,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            return response.json().then((data) => {
              throw new Error(data.message);
            });
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMajLots(~majLots);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  const deleteLot = () => {
    var url = "/api/lots";

    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: lot,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMajLots(~majLots);
        setMaj(~maj);
        setLot("");
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  const handleConfirm = () => {
    var url = "/api/alimentation";

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: lot,
        poids: total.alimentation,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  if (isLoading) {
    return (
      <>
        {lot === "" && !isLoadingInitial ? (
          <NoLots cycle={cycle} setMaj={setMajLots} maj={majLots} />
        ) : (
          <Loading />
        )}
      </>
    );
  }

  return (
    <div id="lot">
      <div className="bg-white shadow-md rounded-lg px-2 py-2 m-0">
        <div className="flex justify-between items-center mb-4 bg-teal-600 text-white rounded-t-md">
          <h1 className="my-5 mx-4 font-light text-6xl">{espece}</h1>
          <p className="mx-2">{comm}</p>
          <section className="flex flex-col mx-4 bg-white shadow-md rounded-lg px-5 py-2">
            <label>Ciclo</label>
            <select
              className="m-0"
              value={cycle}
              onChange={(e) => setCycle(e.target.value)}
            >
              {lotsList.map((Cycle, key) => (
                <option key={key} value={Cycle[1]}>
                  {Cycle[0] + "#" + Cycle[1]}
                </option>
              ))}
            </select>
          </section>
        </div>
        <div className="flex justify-between">
          <div className="">
            {lotsMenu.map((Lot, key) => (
              <button
                className={Lot[2] === 1 ? "btn-red mx-1" : "btn-blue mx-1"}
                key={key}
                onClick={() => setLot(Lot[0])}
              >
                {Lot[1]}
              </button>
            ))}
          </div>
          <div className="flex flex-row">
            {(lotsList !== undefined) &
            (lotsList.find((c) => c[1] === parseInt(cycle)) !== undefined) ? (
              <VoirAnalyse
                bassin={lotsList.find((c) => c[1] === parseInt(cycle))[0]}
              />
            ) : null}
            {(lotsList !== undefined) &
            (lotsList.find((c) => c[1] === parseInt(cycle)) !== undefined) ? (
              <VoirChangementEau
                bassin={lotsList.find((c) => c[1] === parseInt(cycle))[0]}
                maj={maj}
                setMaj={setMaj}
              />
            ) : null}
            <AddLotDialog cycle={cycle} setMaj={setMajLots} maj={majLots} />
            <button className="btn-blue mx-1" onClick={() => resetCycle()}>
              Back
            </button>
          </div>
        </div>
        <div className="md:grid grid-cols-2">
          <section className="bg-gray-100  shadow-md rounded-lg px-1 py-2 m-1">
            <div className="flex justify-between border-b-2 mb-4">
              <h2 className="my-5">Semis</h2>
              <AddSemisDialog
                lot={lot}
                setMaj={setMaj}
                maj={maj}
                espece={espece}
              />
            </div>
            <div className="overflow-x-auto">
              <TableLot
                headers={[
                  "fecha",
                  "peso",
                  "cant/lb",
                  "gr/unidad",
                  "cantidad",
                  "comentario",
                ]}
                deleteUrl="/api/lotData/Semis"
                postUrl={"/api/semis/" + lot}
                setMaj={setMaj}
                maj={maj}
                list={data.semis}
              />
            </div>
          </section>
          <section className="bg-gray-100 shadow-md rounded-lg px-1 py-2 m-1">
            <div className="flex justify-between border-b-2 mb-4">
              <h2 className="my-5">Estadisticas</h2>
              <AddStatsDialog
                lot={lot}
                setMaj={setMaj}
                maj={maj}
                espece={espece}
                mortTheo={total ? total.totalActuel[3] : null}
              />
            </div>
            <div className="overflow-x-auto">
              <TableLot
                headers={[
                  "fecha",
                  "typoestat",
                  "cant/lb",
                  "gr/unidad",
                  "mortalidad",
                  "comentario",
                ]}
                deleteUrl="/api/lotData/Statistiques"
                postUrl={"/api/stats/" + lot}
                setMaj={setMaj}
                maj={maj}
                list={data.statistiques}
              />
            </div>
          </section>
          <section className="bg-gray-100  shadow-md rounded-lg px-1 py-2 m-1 ">
            <div className="flex justify-between border-b-2 mb-4">
              <h2 className="my-5">Pescas</h2>
              <AddPechesDialog
                lot={lot}
                setMaj={setMaj}
                maj={maj}
                espece={espece}
              />
            </div>
            <div className="overflow-x-auto">
              <TableLot
                headers={[
                  "fecha",
                  "peso",
                  "cant/lb",
                  "gr/unidad",
                  "cantidad",
                  "comentario",
                  "destino",
                ]}
                deleteUrl="/api/lotData/Peches"
                postUrl={"/api/peches/" + lot}
                setMaj={setMaj}
                maj={maj}
                list={data.peches}
              />
            </div>
          </section>
          <section className="bg-gray-100  shadow-md rounded-lg px-1 py-2 m-1">
            <div className="flex justify-between border-b-2 mb-4">
              <h2 className="my-5">Total</h2>
              <section>
                <button
                  className="btn-red max-h-10 mx-1"
                  onClick={() => deleteLot()}
                >
                  Eliminar lote
                </button>
                <button
                  className="btn-red max-h-10 mx-1 my-2"
                  onClick={() => endLot()}
                >
                  Terminar el lote
                </button>
              </section>
            </div>
            <div className="overflow-x-auto">
              <TableTotal
                total={total}
                espece={espece}
                data={data}
                setTotal={setTotal}
                handleConfirm={handleConfirm}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
