import React, { useState } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function FormDialog({ espece, lot, setMaj, maj, mortTheo }) {
  const [open, setOpen] = useState(false);
  const [stats, setStats] = useState({
    date: "",
    typeStat: "S",
    quantitelb: "",
    grpoisson: "",
    mortalite: "",
    commentaire: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/stats";
    var quantitelb = 0;
    if (stats.grpoisson !== "") {
      quantitelb = 454 / parseFloat(stats.grpoisson);
    } else {
      quantitelb = parseFloat(stats.quantitelb);
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...stats,
        lot: lot,
        quantitelb: quantitelb,
        date: stats.date,
        mortalite:
          stats.mortalite !== "" ? parseFloat(stats.mortalite) / 100 : mortTheo,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setOpen(false);
    setStats({
      date: "",
      typeStat: "",
      quantitelb: "",
      grpoisson: "",
      mortalite: "",
      commentaire: "",
    });
  };

  return (
    <div>
      <div>
        <button
          className="btn-teal"
          type="button"
          onClick={() => setOpen(true)}
        >
          Añadir estadistica
        </button>
        {open ? (
          <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  {/*header*/}
                  <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                    <h2>Añadir estadistica</h2>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div>
                  <form className="mt-5" onSubmit={handleSubmit}>
                    <div className="md:grid grid-cols-3 gap-2 relative p-6">
                      <section className="flex flex-col">
                        <label>Fecha</label>
                        <input
                          className="m-0"
                          type="date"
                          required
                          placeholder="Fecha"
                          value={stats.date}
                          onChange={(e) =>
                            setStats({ ...stats, date: e.target.value })
                          }
                        />
                      </section>
                      <section className="flex flex-col">
                        <label>Tipo de estadistica</label>
                        <select
                          className="m-0"
                          value={stats.typeStat}
                          required
                          onChange={(e) =>
                            setStats({ ...stats, typeStat: e.target.value })
                          }
                        >
                          <option value={"S"}>S</option>
                          <option value={"E"}>E</option>
                        </select>
                      </section>
                      {espece &&
                      (espece.substring(0, 7) === "Tilapia" ||
                        espece.substring(espece.length - 7, espece.length) ===
                          "tilapia" ||
                        espece.substring(0, 5) === "Carpe" ||
                        espece.substring(0, 9) === "Colossoma") ? (
                        <section className="flex flex-col">
                          <label>Gr par poissons</label>
                          <input
                            type="text"
                            required
                            value={stats.grpoisson}
                            onChange={(e) =>
                              setStats({
                                ...stats,
                                grpoisson: e.target.value,
                              })
                            }
                          />
                        </section>
                      ) : (
                        <section className="flex flex-col">
                          <label>Cantitad por libras</label>
                          <input
                            type="text"
                            required
                            value={stats.quantitelb}
                            onChange={(e) =>
                              setStats({
                                ...stats,
                                quantitelb: e.target.value,
                              })
                            }
                          />
                        </section>
                      )}
                      <section className="flex flex-col">
                        <label>Mortalidad</label>
                        <input
                          type="text"
                          required
                          value={stats.mortalite}
                          onChange={(e) => {
                            setStats({
                              ...stats,
                              mortalite: e.target.value,
                            });
                          }}
                        />
                      </section>
                      <section className="flex flex-col">
                        <label>Comentario</label>
                        <input
                          type="text"
                          value={stats.commentaire}
                          onChange={(e) =>
                            setStats({ ...stats, commentaire: e.target.value })
                          }
                        />
                      </section>
                    </div>
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="btn-red-transparent"
                        type="button"
                        onClick={() => setOpen(false)}
                      >
                        Cerrar
                      </button>
                      <button className="btn-teal" type="submit">
                        Confirmar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
      </div>
    </div>
  );
}
