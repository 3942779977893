import { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";
import ShowChangementEau from "components/ChangementEau/ShowChangementEau";
import ShowTotal from "components/ChangementEau/ShowTotal";

export default function AddChangementEau() {
  const [changementEau, setChangementEau] = useState({
    bassin: 4,
    date: new Date().toISOString().substring(0, 10),
    heure: "",
    pompe: 1,
    type: "L",
  });
  const [bassinsList, setBassinsList] = useState([""]);
  const [pompesList, setPompesList] = useState([""]);
  const [maj, setMaj] = useState(true);

  useEffect(() => {
    var url = "/api/pompes/";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setBassinsList(response.bassins.sort((a, b) => (a[1] > b[1] ? 1 : -1)));
        setPompesList(response.pompes.sort((a, b) => (a[1] > b[1] ? 1 : -1)));
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [maj]);

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/pompes/";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(changementEau),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setChangementEau({
      bassin: 4,
      date: new Date().toISOString().substring(0, 10),
      heure: "",
      pompe: 1,
      type: "L",
    });
  };

  return (
    <div>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <div className="flex justify-between border-b-2 mb-4">
          <h2 className="my-5">Añadir un recambio de agua</h2>
        </div>
        <form
          className="md:grid md:grid-cols-3 xl:grid-cols-6 gap-2"
          onSubmit={handleSubmit}
        >
          <section className="flex flex-col">
            <label>Estanque</label>
            <select
              className="m-0 h-12"
              name="role"
              value={changementEau.bassin}
              onChange={(e) =>
                setChangementEau({
                  ...changementEau,
                  bassin: parseInt(e.target.value),
                })
              }
            >
              {bassinsList.map((Bassin, key) => (
                <option key={key} value={Bassin[0]}>
                  {Bassin[1]}
                </option>
              ))}
            </select>
          </section>
          <section className="flex flex-col">
            <label>Bomba</label>
            <select
              className="m-0  h-12"
              name="role"
              value={changementEau.pompe}
              onChange={(e) =>
                setChangementEau({
                  ...changementEau,
                  pompe: parseInt(e.target.value),
                })
              }
            >
              {pompesList.map((pompe, key) => (
                <option key={key} value={pompe[0]}>
                  {pompe[1]}
                </option>
              ))}
            </select>
          </section>
          <section className="flex flex-col">
            <label>Fecha</label>
            <input
              className="m-0 h-12"
              type="date"
              value={changementEau.date}
              onChange={(e) =>
                setChangementEau({ ...changementEau, date: e.target.value })
              }
            />
          </section>
          <section className="flex flex-col">
            <label>Hora</label>
            <input
              className="m-0 h-12"
              type="number"
              value={changementEau.heure}
              onChange={(e) =>
                setChangementEau({ ...changementEau, heure: e.target.value })
              }
            />
          </section>
          <section className="flex flex-col">
            <label>Tipo</label>
            <select
              className="m-0  h-12"
              value={changementEau.type}
              onChange={(e) =>
                setChangementEau({
                  ...changementEau,
                  type: e.target.value,
                })
              }
            >
              <option value="L">L</option>
              <option value="R">R</option>
            </select>
          </section>
          <button
            type="submit"
            className="btn-teal h-12 self-center mt-5 max-w-xs"
          >
            Añadir
          </button>
        </form>
      </div>
      <ShowChangementEau
        bassinsList={bassinsList}
        pompesList={pompesList}
        maj={maj}
        setMaj={setMaj}
      />
      <ShowTotal />
    </div>
  );
}
