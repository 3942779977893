import RowEditable from "components/RowEditable";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function TableAeration({
  list,
  bassinsList,
  aerateursList,
  maj,
  setMaj,
}) {
  // Delete function
  const deleteRow = (id) => {
    var url = "/api/aerateurs";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(~maj);
  };

  const handleEdit = (edited, id) => {
    var data = {
      id: id,
      bassin: edited[0].value_id,
      aerateur: edited[1].value_id,
      date_debut: new Date(edited[3].value).getTime(),
      date_fin:
        edited[4].value !== "" ? new Date(edited[4].value).getTime() : null,
    };
    console.log(data, edited[3].value, edited[4].value);
    if (data.date_debut < data.date_fin || data.date_fin === null) {
      var url = "/api/aerateursList";
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };
      authFetch(url, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((data) => {
              throw new Error(data.message);
            });
          }
        })
        .then((response) => {
          toast.success(response.message);
        })
        .catch((e) => toast.error(e.name + ": " + e.message));
      setMaj(~maj);
    } else {
      toast.warn("Fecha final antes de fecha inicial");
    }
  };

  return (
    <>
      {/* Table items */}
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white pr-10 pl-2 py-4 text-left">
              Estanque
            </th>
            <th className="text-sm font-medium text-white pr-10 pl-2 py-4 text-left">
              Aireador
            </th>
            <th className="text-sm font-medium text-white pr-10 pl-2 py-4 text-left">
              Potencia
            </th>
            <th className="text-sm font-medium text-white pr-10 pl-2 py-4 text-left">
              Fecha inicio
            </th>
            <th className="text-sm font-medium text-white pr-10 pl-2 py-4 text-left">
              Fecha fin
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(
            (
              {
                id,
                Estanque,
                Estanque_id,
                Aireador,
                Potencia,
                Aireador_id,
                Fecha_inicio,
                Fecha_fin,
              },
              key
            ) => {
              var tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
              const row = [
                {
                  name: "Estanque",
                  value: Estanque,
                  value_id: Estanque_id,
                  editable: true,
                  type: "select",
                  selectList: bassinsList,
                },
                {
                  name: "Aireador",
                  value: Aireador,
                  value_id: Aireador_id,
                  editable: true,
                  type: "select",
                  selectList: aerateursList,
                },
                {
                  name: "Potencia",
                  value: Potencia,
                  editable: false,
                },
                {
                  name: "Fecha_inicio",
                  value: new Date(Fecha_inicio - tzoffset)
                    .toISOString()
                    .substring(0, 16),
                  editable: true,
                  type: "input-date-time",
                },
                {
                  name: "Fecha_fin",
                  value:
                    Fecha_fin === null
                      ? ""
                      : new Date(Fecha_fin - tzoffset)
                          .toISOString()
                          .substring(0, 16),
                  editable: true,
                  type: "input-date-time",
                },
              ];
              return (
                <RowEditable
                  key={id}
                  id={id}
                  row={row}
                  color={key % 2 === 1 ? "teal-50" : "white"}
                  handleEdit={handleEdit}
                  deleteRow={deleteRow}
                />
              );
            }
          )}
        </tbody>
      </table>
    </>
  );
}
