import React, { useEffect, useState } from "react";
import "./assets/css/App.css";
import { login, useAuth, logout } from "./auth";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import logo from "assets/img/logo.png";

import Finca from "./layouts/finca";
import { toast } from "react-toastify";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <Login />
        </Route>
        <PrivateRoute path="/" component={Finca} />
      </Switch>
    </Router>
  );
}

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [logged] = useAuth();

  const onSubmitClick = (e) => {
    e.preventDefault();
    let opts = {
      username: username,
      password: password,
    };
    fetch("/api/login", {
      method: "post",
      body: JSON.stringify(opts),
    })
      .then((r) => r.json())
      .then((token) => {
        if (token.access_token) {
          login(token);
        } else {
          toast.warn("Contraseña o nombre de usuario equivocado");
        }
      });
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className="flex justify-center items-center h-screen w-screen bg-sky-900">
      {!logged ? (
        <div className="grid grid-cols-2 bg-white shadow-md rounded-lg px-5 py-2 m-2 items-center w-1/4">
          <div>
            <div className="mb-5">
              <h1 className="font-medium text-3xl">Login</h1>
            </div>
            <section className="flex flex-col">
              <label>Usuario</label>
              <input
                required
                placeholder="Nombre de usuario"
                onChange={handleUsernameChange}
              />
            </section>

            <section className="flex flex-col">
              <label>Contraseña</label>
              <input
                type="password"
                required
                placeholder="Contraseña"
                onChange={handlePasswordChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSubmitClick(e);
                  }
                }}
              />
            </section>
            <div className="w-full flex justify-center">
              <button
                className="btn-blue"
                style={{
                  background:
                    "linear-gradient(to right,#ee7724,#d8363a,#dd3675,#b44593)",
                }}
                onClick={onSubmitClick}
              >
                Login
              </button>
            </div>
          </div>
          <div>
            <img src={logo} alt="logo" className="" />
          </div>
        </div>
      ) : (
        <Redirect to="/finca" />
      )}
    </div>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [logged] = useAuth();

  useEffect(() => {
    if (localStorage.REACT_TOKEN_AUTH_KEY) {
      const tokken = JSON.parse(localStorage.REACT_TOKEN_AUTH_KEY);
      if (tokken.error) {
        logout();
      }
    }
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        logged ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
