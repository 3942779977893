import RowEditable from "components/RowEditable";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function TableVentesCrevettes({ list, maj, setMaj }) {
  const handleEdit = (edited, id) => {
    var data = {
      id: id,
      fecha: edited[0].value,
      u5: edited[1].value,
      u6: edited[2].value,
      u8: edited[3].value,
      u10: edited[4].value,
      u12: edited[5].value,
      u15: edited[6].value,
      u16: edited[7].value,
      u21: edited[8].value,
      pelado: edited[9].value,
      peso: edited[10].value,
    };
    var url = "/api/ventes/crevettes";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(~maj);
  };

  return (
    <>
      {/* Table items */}
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Fecha
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              u5
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              u6
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              u8
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              u10
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              u12
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              u15
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              u16/20
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              u21/25
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              pelado
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              total venta
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              total pesca
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              % perdida
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(
            (
              {
                id,
                fecha,
                u5,
                u6,
                u8,
                u10,
                u12,
                u15,
                u16,
                u21,
                pelado,
                peso,
                total,
                perdida,
              },
              key
            ) => {
              const row = [
                {
                  name: "fecha",
                  value: fecha,
                  editable: true,
                  type: "input-date",
                },
                {
                  name: "u5",
                  value: u5,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "u6",
                  value: u6,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "u8",
                  value: u8,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "u10",
                  value: u10,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "u12",
                  value: u12,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "u15",
                  value: u15,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "u16/20",
                  value: u16,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "u21/25",
                  value: u21,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "pelado",
                  value: pelado,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "peso",
                  value: peso,
                  editable: true,
                  type: "input-number",
                },
                {
                  name: "total",
                  value: total,
                  editable: false,
                },
                {
                  name: "perdida",
                  value: perdida,
                  editable: false,
                },
              ];
              return (
                <RowEditable
                  key={id}
                  id={id}
                  row={row}
                  color={key % 2 === 1 ? "teal-50" : "white"}
                  handleEdit={handleEdit}
                  removeDelete={true}
                />
              );
            }
          )}
        </tbody>
      </table>
    </>
  );
}
