import { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function ChangeUser({ majTop, setMajTop, roleList, roleTrad }) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({
    name: "",
    role: "",
    password: "",
    confirmPassword: "",
  });
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    authFetch("/api/user")
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setUser({
          name: response.name,
          role: response.role,
          password: "",
          confirmPassword: "",
        });
        setIsAdmin(response.role === 1 || response.role === 2);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [majTop]);

  const handleSubmitUser = (e) => {
    e.preventDefault();
    if (user.password !== user.confirmPassword) {
      toast.warn("Las contraseñas no coinciden");
    } else if (user.password.length < 6) {
      toast.warn("Contraseña demasiado corta");
    } else {
      var url = "/api/user";
      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: user.name,
          email: user.email,
          role: user.role,
          password: user.password,
        }),
      };
      authFetch(url, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            return response.json().then((data) => {
              throw new Error(data.message);
            });
          }
        })
        .then((response) => {
          toast.success("Todo bien!");
          setIsLoading(true);
          setMajTop(~majTop);
        })
        .catch((e) => toast.error(e.name + ": " + e.message));
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-full">
        <div class="loader"></div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow-md rounded-lg p-5 m-2">
      <h2 className="border-b-2 ">Cambiar usuario</h2>
      <form className="mt-4" onSubmit={handleSubmitUser}>
        <div className="md:grid grid-cols-2 gap-2">
          <section className="flex flex-col">
            <label>Nombre</label>
            <input
              type="text"
              name="name"
              value={user.name}
              onChange={(e) =>
                setUser((prev) => {
                  return { ...prev, name: e.target.value };
                })
              }
              placeholder="Nombre"
            />
          </section>
          <section className="flex flex-col">
            <label>Contraseña</label>
            <input
              type="password"
              name="password"
              value={user.password}
              onChange={(e) =>
                setUser((prev) => {
                  return { ...prev, password: e.target.value };
                })
              }
              placeholder="Contraseña"
            />
          </section>
          <section className="flex flex-col">
            <label>Confirmar Contraseña</label>
            <input
              type="password"
              name="passwordConfirm"
              value={user.confirmPassword}
              onChange={(e) =>
                setUser((prev) => {
                  return { ...prev, confirmPassword: e.target.value };
                })
              }
              placeholder="Confirmar Contraseña"
            />
          </section>
          {isAdmin ? (
            <section className="flex flex-col">
              <label>Función</label>
              <select
                name="role"
                value={user.role}
                onChange={(e) =>
                  setUser((prev) => {
                    return { ...prev, role: e.target.value };
                  })
                }
                placeholder="Función"
              >
                {roleList.map((r, key) => {
                  return (
                    <option key={key} value={r[0]}>
                      {roleTrad[r[1]]}
                    </option>
                  );
                })}
              </select>
            </section>
          ) : null}
          <button
            type="submit"
            className="h-12 self-center mb-5 bg-teal-700 text-white font-bold py-2 px-8 rounded shadow border-2 border-teal-700 hover:bg-teal-600 transition-all duration-300"
          >
            Confirmar
          </button>
        </div>
      </form>
    </div>
  );
}
