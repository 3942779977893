export default function ShowAeration({ open, setOpen, bassin, aerations }) {
  return (
    <div>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2> Uso de aireador en {bassin}</h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                </div>
                <div className="mt-2 px-5">
                  <table width="100%" className="min-w-full ">
                    <thead className="bg-teal-500 rounded">
                      <tr>
                        <th className="text-sm font-medium text-white px-6 py-4 text-left">
                          Estanque
                        </th>
                        <th className="text-sm font-medium text-white px-6 py-4 text-left">
                          Aireador
                        </th>
                        <th className="text-sm font-medium text-white px-6 py-4 text-left">
                          Fecha inicio
                        </th>
                        <th className="text-sm font-medium text-white px-6 py-4 text-left">
                          Fecha fin
                        </th>
                        <th />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {aerations
                        ? aerations.map((r, key) => {
                            var tzoffset =
                              new Date().getTimezoneOffset() * 60000; //offset in milliseconds
                            return (
                              <tr
                                key={key}
                                className={
                                  key % 2 === 0
                                    ? "bg-white border-b hover:bg-gray-100"
                                    : "bg-teal-50 border-b hover:bg-gray-100"
                                }
                              >
                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                  {r.Estanque}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                  {r.Aireador}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap">
                                  <input
                                    className={
                                      " p-0 rounded-sm m-1 bg-inherit border-transparent"
                                    }
                                    type="datetime-local"
                                    disabled={true}
                                    value={new Date(r.Fecha_inicio - tzoffset)
                                      .toISOString()
                                      .substring(0, 16)}
                                  />
                                </td>
                                <td
                                  key={key}
                                  className=" text-sm text-gray-900 font-light px-6 py-2 whitespace-nowrap "
                                >
                                  {r.Fecha_fin === null ? (
                                    ""
                                  ) : (
                                    <input
                                      className={
                                        " p-0 rounded-sm m-1 bg-inherit border-transparent"
                                      }
                                      type="datetime-local"
                                      disabled={true}
                                      value={new Date(r.Fecha_fin - tzoffset)
                                        .toISOString()
                                        .substring(0, 16)}
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="btn-red-transparent"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
