import React, { useState, useEffect } from "react";
import "assets/css/App.css";
import { authFetch } from "auth";
import AddEnregistrementDB from "components/Dialog/AddEnregistrementDB";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import ShowTable from "components/ShowTable";

export default function Table() {
  const [table, setTable] = useState("Bassins");
  const [tableHeaders, setTableHeaders] = useState("");
  const [tableData, setTableData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [maj, setMaj] = useState(true);

  useEffect(() => {
    var url = "/api/table/" + table;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setTableHeaders(response.headers);
        setTableData(response.data);
        setIsLoading(false);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(false);
  }, [maj, table]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div id="#finca">
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
        <div className="flex justify-between border-b-2 mb-4">
          <h2 className="mt-5">Table</h2>
        </div>
        <div className="flex justify-center">
          <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2 flex justify-center items-center">
            <section className="flex flex-col mx-5">
              <label>Table</label>
              <select
                className="mb-0"
                name="bassin"
                value={table}
                onChange={(e) => setTable(e.target.value)}
              >
                <option value={"Bassins"}>Estanque</option>
                <option value={"Especes"}>Especie</option>
                <option value={"Pompes"}>Bomba</option>
                <option value={"Mortalite"}>Mortalidad</option>
                <option value={"TypeAliment"}>Tipo de alimento</option>
                <option value={"Croissance"}>Crecimiento</option>
                <option value={"Aerateurs"}>Aerador</option>
              </select>
            </section>
            <AddEnregistrementDB type={table} setMaj={setMaj} maj={maj} />
          </div>
        </div>
        <ShowTable
          maj={maj}
          setMaj={setMaj}
          list={tableData}
          headers={tableHeaders}
          table={table}
        />
      </div>
    </div>
  );
}
