import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function AddClientDialog({
  setMaj,
  maj,
  id,
  setDeleteClientVisible,
}) {
  const handleConfirm = () => {
    var url = "/api/deleteClients";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: id,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        if (response.error === "true") {
          toast.warn(response.message);
        } else {
          toast.success(response.message);
        }
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setDeleteClientVisible(false);
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
              <h2>Borrar el cliente</h2>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setDeleteClientVisible(false)}
              >
                <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-slate-500 text-lg leading-relaxed">
                ¿Está seguro de que quiere borrar este cliente?
              </p>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="btn-red-transparent"
                type="button"
                onClick={() => setDeleteClientVisible(false)}
              >
                Cerrar
              </button>
              <button
                className="btn-teal"
                type="button"
                onClick={() => handleConfirm()}
              >
                Borrar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
