import React, { useState, useEffect } from "react";
import { authFetch } from "auth";
import AlimentationForm from "components/Alimentation/AlimentationForm";
import CsvDownload from "react-json-to-csv";
import SetDate from "components/Dialog/SetDate";
import { toast } from "react-toastify";

export default function Alimentation() {
  const [isLoading, setIsLoading] = useState(true);
  const [aliData, setAliData] = useState([]);
  const [alimentList, setAlimentList] = useState([]);
  const [cyclesList, setCyclesList] = useState(undefined);
  const [cycle, setCycle] = useState(undefined);
  const [maj, setMaj] = useState(false);
  const [poidsAM, setPoidsAM] = useState("");
  const [poidsPM, setPoidsPM] = useState("");
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [total, setTotal] = useState("");

  useEffect(() => {
    setIsLoading(true);
  }, [date]);

  useEffect(() => {
    let isCancelled = false;
    var url = "/api/alimentation/" + date + "/false";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        if (!isCancelled) {
          setCyclesList(
            response.cycles.sort((a, b) => (a.Bassin > b.Bassin ? 1 : -1))
          );
          setAliData(
            transformData(
              response.cycles.sort((a, b) => (a.Bassin > b.Bassin ? 1 : -1)),
              response.aliments
            )
          );
          setAlimentList(response.aliments);
          setIsLoading(false);
        }
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    return () => {
      isCancelled = true;
    };
  }, [date]);

  useEffect(() => {
    let isCancelled = false;
    var url = "/api/alimentationTotal/" + date;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        if (!isCancelled) {
          setTotal(response.total);
          setMaj(false);
        }
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    return () => {
      isCancelled = true;
    };
  }, [date, maj]);

  useEffect(() => {
    if (cycle && cycle.ali && maj) {
      if (cycle.Poids_pm) {
        setPoidsAM(cycle.Poids - cycle.Poids_pm);
        setPoidsPM(cycle.Poids_pm);
      } else {
        setPoidsAM(cycle.Poids);
        setPoidsPM("");
      }
    } else if (cycle && maj) {
      setPoidsAM("");
      setPoidsPM("");
    }
    setMaj(false);
  }, [cycle, maj]);

  const changeDate = (d) => {
    var result = new Date(date + "T12:00:00");
    result.setDate(result.getDate() + d);
    setDate(result.toISOString().substring(0, 10));
    setCycle(undefined);
  };

  const handleChangeCycle = (id) => {
    setCycle({
      ...cyclesList[id],
      id: id,
      Type_Aliment: cyclesList[id].Type_Aliment
        ? cyclesList[id].Type_Aliment
        : 2,
    });
    setMaj(true);
  };

  const handleDateChange = (newDate) => {
    if (newDate.length === 10) {
      setDate(newDate);
      setIsLoading(true);
    }
  };

  const transformData = (c, aliments) => {
    if (c !== undefined) {
      let temp = [];
      c.forEach((data) => {
        let ali = aliments.find((a) => a[0] === data.Type_Aliment);
        if (ali === undefined) {
          temp.push({
            Estanque: data.Bassin,
            Alimento: 0,
            Cantidad: 0,
          });
        } else {
          temp.push({
            Estanque: data.Bassin,
            Alimento: ali[1].replace('"', " "),
            Cantidad: data["poids aliment a donner"],
          });
        }
      });
      return temp;
    }
  };

  return (
    <div className="">
      <div className="flex flex-row justify-center min-w-full">
        <button className="btn-teal" onClick={() => changeDate(-1)}>
          {"<<"}
        </button>
        <SetDate date={date} handleDateChange={handleDateChange} />
        <button className="btn-teal" onClick={() => changeDate(1)}>
          {">>"}
        </button>
        <CsvDownload
          data={aliData}
          filename={"alimentacion-" + date + ".csv"}
          className="btn-blue mx-2"
        >
          Descargar alimentacion
        </CsvDownload>
      </div>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2 flex flex-row justify-between">
        <h4 className="my-2">Total</h4>
        <div className="text-md grid grid-cols-2 items-center ">
          {total
            ? total.map((t) => {
                return (
                  <p className="border-l-4 border-gray-600 px-2">
                    <b>{t[0]}</b> : {t[1]} lbs{" "}
                  </p>
                );
              })
            : null}
        </div>
      </div>
      <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2 grid grid-cols-3">
        <div className="flex flex-col col-span-1 overflow-y-scroll overflow-x-hidden max-h-4/5">
          {cyclesList &&
            cyclesList.map((c, key) => (
              <div id={key + 1000} className="min-w-full h-16 pb-1">
                <button
                  className={
                    !c.ali
                      ? "font-bold text-xl bg-sky-600 text-white min-w-full h-14  rounded-md hover:bg-sky-700 active:bg-sky-800 transition-all duration-300"
                      : "font-bold text-xl bg-green-600 text-white min-w-full h-14  rounded-md hover:bg-green-700 active:bg-green-800 transition-all duration-300"
                  }
                  id={key}
                  onClick={(e) => {
                    e.target.id === ""
                      ? handleChangeCycle(e.target.offsetParent.id)
                      : handleChangeCycle(e.target.id);
                  }}
                >
                  {c.Bassin}
                </button>
              </div>
            ))}
        </div>
        <div className="col-span-2 ">
          {cycle ? (
            <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
              <div className="flex justify-center mb-4 bg-teal-600 text-white rounded-t-md">
                <h1 className="my-5 font-light text-6xl">{cycle.Bassin}</h1>
              </div>
              <AlimentationForm
                cycle={cycle}
                cyclesList={cyclesList}
                setCyclesList={setCyclesList}
                setCycle={setCycle}
                poidsAM={poidsAM}
                setPoidsAM={setPoidsAM}
                poidsPM={poidsPM}
                setPoidsPM={setPoidsPM}
                alimentList={alimentList}
                date={date}
                setMaj={setMaj}
                maj={maj}
              />
            </div>
          ) : isLoading ? (
            <div className="flex justify-center items-center min-h-full">
              <div className="loader"></div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
