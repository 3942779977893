import React, { useState, useEffect } from "react";
import { authFetch } from "auth";
import CyclesTable from "components/Cycle/CyclesTable";
import { toast } from "react-toastify";
import Loading from "components/Loading";
import ShowAncien from "components/Cycle/ShowAncien";
import AddCycleDialog from "components/Cycle/AddCycleDialog";
import Lots from "components/Lots/Lots";

export default function Cycles() {
  const headers = [
    "Estanque",
    "Fecha lleno",
    "Fecha ultima pesca",
    "# meses",
    "Alimentacion",
    "Alimentacion téo",
    "Superficie",
    "Especie",
    "Peso sembrado",
    "Peso pescado",
    "Peso actual",
    "Mortalidad",
    "Rendimiento",
    "Alimentacion total",
    "Indice de conversion",
  ];
  const [tableData, setTableData] = useState([]);
  const [espece, setEspece] = useState("Tout");
  const [maj, setMaj] = useState(true);
  const [isLoading, setIsLoading] = useState([true, true]);
  const [bassinsList, setBassinsList] = useState([]);
  const [especesList, setEspecesList] = useState([]);
  const [lot, setLot] = useState(undefined);

  useEffect(() => {
    var url = "/api/cycles/" + espece;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setTableData(response.cycles);
        setIsLoading((s) => {
          return [false, s[1]];
        });
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [espece, maj]);

  useEffect(() => {
    var url = "/api/especes";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setBassinsList(response.bassins);
        setEspecesList(response.especes);
        setIsLoading((s) => {
          return [s[0], false];
        });
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, []);

  const handleClick = (id) => {
    setLot(id);
  };

  const handleReset = () => {
    setLot(undefined);
    setMaj(~maj);
  };

  if (isLoading[0] || isLoading[1]) {
    return <Loading />;
  }

  return (
    <>
      {lot ? (
        <Lots cycle_={lot} resetCycle={handleReset} />
      ) : (
        <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
          <div className="flex justify-between border-b-2 mb-4">
            <h2 className="my-5">Ciclos</h2>
            <section className="flex flex-col">
              <label>Espece</label>
              <select
                className="m-0"
                name="role"
                value={espece}
                onChange={(e) => setEspece(e.target.value)}
              >
                <option value="Tout">Todo</option>
                <option value="tilapia">Tilapia</option>
                <option value="crevette">Camarones</option>
                <option value="carpe">Carpa</option>
                <option value="colossoma">Colossoma</option>
              </select>
            </section>
            <AddCycleDialog
              maj={maj}
              setMaj={setMaj}
              bassinsList={bassinsList}
              especesList={especesList}
            />
            <ShowAncien bassinsList={bassinsList} />
          </div>
          <CyclesTable
            handleClick={handleClick}
            headers={headers}
            list={tableData.sort((a, b) => (a.Estanque > b.Estanque ? 1 : -1))}
            maj={maj}
            setMaj={setMaj}
          />
        </div>
      )}
    </>
  );
}
