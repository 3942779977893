import React, { useState } from "react";
import { AiOutlineEdit, AiOutlineCheck, AiOutlineDelete } from "react-icons/ai";

export default function RowEditable({
  row,
  id,
  color,
  handleEdit,
  deleteRow,
  removeDelete,
  handleClick,
  removeEdit,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [newRow, setNewRow] = useState(row);

  const handleChange = (value, index, isSelect, name) => {
    var temp = [...newRow];
    if (isSelect) {
      temp[index].value_id = parseInt(value);
      temp[index].value = name;
    } else {
      temp[index].value = value;
    }
    setNewRow(temp);
  };

  const handleDone = () => {
    setIsEditing(false);
    handleEdit(newRow, id);
  };

  const handleClickIn = (i) => {
    if (handleClick !== undefined && !isEditing) {
      handleClick(i);
    }
  };

  return (
    <tr
      id={id}
      onClick={(e) => handleClickIn(e.target.id)}
      className={"bg-" + color + " border-b hover:bg-gray-100"}
    >
      <td className="bg-teal-50" hidden={true} />
      {row.map((item, key) => {
        if (!isEditing || !item.editable) {
          if (item.type === "input-date-time") {
            return (
              <td
                id={id}
                key={key}
                className="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap "
              >
                <input
                  className={
                    newRow[key].value === ""
                      ? "bg-orange-200  p-0 rounded-sm m-1 border-transparent"
                      : " p-0 rounded-sm m-1 bg-inherit border-transparent"
                  }
                  type="datetime-local"
                  disabled={true}
                  value={newRow[key].value}
                />
              </td>
            );
          } else if (item.type === "input-date") {
            return (
              <td
                id={id}
                key={key}
                className=" text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap "
              >
                <input
                  className="p-0 rounded-sm m-0 bg-inherit border-transparent"
                  type="date"
                  disabled={true}
                  value={newRow[key].value}
                />
              </td>
            );
          }
          return (
            <td
              id={id}
              key={key}
              className="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap"
            >
              {item.value}
            </td>
          );
        } else if (item.type === "input-number") {
          return (
            <td
              id={id}
              key={key}
              className="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap"
            >
              <input
                className="m-0 p-0 bg-inherit border-teal-200 border-2 w-20"
                type="number"
                placeholder="Price"
                value={newRow[key].value}
                onChange={(e) =>
                  handleChange(parseFloat(e.target.value), key, false, null)
                }
                onKeyDown={(e) => (e.key === "Enter" ? handleDone() : null)}
              />
            </td>
          );
        } else if (item.type === "input-date-time") {
          return (
            <td
              id={id}
              key={key}
              className="text-sm text-gray-900 font-light px-2 py-2 whitespace-nowrap"
            >
              <input
                className="m-0 p-0  bg-inherit border-transparent"
                type="datetime-local"
                placeholder="Fecha"
                value={newRow[key].value}
                onChange={(e) => handleChange(e.target.value, key, false, null)}
              />
            </td>
          );
        } else if (item.type === "input-date") {
          return (
            <td
              id={id}
              key={key}
              className="text-sm text-gray-900 font-light  px-2 py-2 whitespace-nowrap"
            >
              <input
                className="m-0 p-0  bg-inherit border-teal-200 border-2"
                type="date"
                placeholder="Fecha"
                value={newRow[key].value}
                onChange={(e) => handleChange(e.target.value, key, false, null)}
              />
            </td>
          );
        } else if (item.type === "select") {
          return (
            <td
              id={id}
              key={key}
              className="text-sm text-gray-900 font-light  px-2 py-2 whitespace-nowrap"
            >
              <select
                className="m-0  p-0  bg-inherit border-teal-200 border-2"
                value={newRow[key].value_id}
                onChange={(e) =>
                  handleChange(
                    e.target.value,
                    key,
                    true,
                    item.selectList.filter(
                      (i) => i[0] === parseInt(e.target.value)
                    )[0][1]
                  )
                }
              >
                {item.selectList.map((i, key2) => (
                  <option key={key2} value={i[0]}>
                    {i[1]}
                  </option>
                ))}
              </select>
            </td>
          );
        } else if (item.type === "input-text") {
          return (
            <td
              id={id}
              key={key}
              className="text-sm text-gray-900 font-light  px-2 py-1whitespace-nowrap"
            >
              <input
                className="m-0 p-0  bg-inherit border-teal-200 border-2 "
                type="text"
                placeholder="..."
                value={newRow[key].value}
                onChange={(e) => handleChange(e.target.value, key, false, null)}
                onKeyDown={(e) => (e.key === "Enter" ? handleDone() : null)}
              />
            </td>
          );
        } else if (item.type === "input-text-short") {
          return (
            <td
              id={id}
              key={key}
              className="text-sm text-gray-900 font-light  px-2 py-1whitespace-nowrap"
            >
              <input
                className="m-0 p-0  bg-inherit border-teal-200 border-2 max-w-[6rem]"
                type="text"
                placeholder="..."
                value={newRow[key].value}
                onChange={(e) => handleChange(e.target.value, key, false, null)}
                onKeyDown={(e) => (e.key === "Enter" ? handleDone() : null)}
              />
            </td>
          );
        } else {
          return null;
        }
      })}
      {removeEdit ? null : (
        <td>
          {isEditing ? (
            <button onClick={handleDone}>
              <AiOutlineCheck className="text-green-500 font-bold text-2xl" />
            </button>
          ) : (
            <button onClick={() => setIsEditing(true)}>
              <AiOutlineEdit className="text-green-500   text-2xl" />
            </button>
          )}
        </td>
      )}
      {!removeDelete ? (
        <td>
          {isEditing ? null : (
            <button onClick={() => deleteRow(id)}>
              <AiOutlineDelete className="text-red-500 font-bold text-2xl" />
            </button>
          )}
        </td>
      ) : null}
    </tr>
  );
}
