import { authFetch } from "auth";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function Lots({ cycle, setMaj, maj }) {
  const [newLot, setNewLot] = useState({
    espece_id: "",
    cycle_id: cycle,
    commentaire: "",
  });
  const [especeList, setEspeceList] = useState([]);

  useEffect(() => {
    var url = "/api/especes";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setEspeceList(response.especes);
        setNewLot((old) => {
          return { ...old, espece_id: response.especes[0][0] };
        });
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/lots";
    console.log(newLot);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...newLot }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setNewLot({
      espece_id: "",
      cycle_id: cycle,
      commentaire: "",
    });
  };

  return (
    <div className="relative w-auto my-6 mx-auto max-w-3xl">
      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
        <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
          <h2>Ciclo {cycle} sin lote</h2>
        </div>
        <form className="mt-5" onSubmit={handleSubmit}>
          <div className="flex flex-col p-6 py-0">
            <p>
              Este ciclo no tiene lotes asociados. Por favor, cree un lote para
              este ciclo.
            </p>
          </div>
          <div className="md:grid grid-cols-3 gap-2 relative p-6 items-center">
            <section className="flex flex-col">
              <label>Especie del lote</label>
              <select
                className="m-0"
                value={newLot.espece_id}
                onChange={(e) =>
                  setNewLot({ ...newLot, espece_id: e.target.value })
                }
              >
                {especeList.map((e, key) => {
                  return (
                    <option key={key} value={e[0]}>
                      {e[1]}
                    </option>
                  );
                })}
              </select>
            </section>
            <section className="flex flex-col">
              <label>Comentario</label>
              <input
                className="m-0"
                type="text"
                value={newLot.commentaire}
                onChange={(e) =>
                  setNewLot({ ...newLot, commentaire: e.target.value })
                }
              />
            </section>
            <button className="btn-teal max-h-12 mt-5" type="submit">
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
