import { useState, useEffect } from "react";
import { authFetch } from "auth";
import TableAeration from "components/Aerateur/TableAeration";
import { toast } from "react-toastify";

const getLastMonth = (date = new Date()) => {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 30);

  return previous;
};

export default function ShowAeration({
  bassinsList,
  aerateursList,
  maj,
  setMaj,
  defaultBassin,
}) {
  const [list, setList] = useState([]);
  const [dates, setDates] = useState({
    first: getLastMonth().toISOString().substring(0, 10),
    second: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
      .toISOString()
      .substring(0, 10),
  });
  const [bassin, setBassin] = useState(defaultBassin ? defaultBassin : "Todos");

  useEffect(() => {
    var first = new Date(dates.first);
    first.setHours(0);
    first = first.getTime();
    var second = new Date(dates.second);
    second.setHours(6);
    second.setDate(second.getDate() + 1);
    second = second.getTime();
    var url = "/api/aerateurs/" + first + "/" + second + "/" + bassin;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setList(
          response.aeration.sort((a, b) =>
            a.Fecha_inicio < b.Fecha_inicio ? 1 : -1
          )
        );
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [dates, maj, bassin]);

  return (
    <div className="bg-white shadow-md rounded-lg px-5 py-2 m-2">
      <div className="flex justify-between border-b-2 mb-4">
        <h2 className="my-5">Uso de aireador</h2>
        <div className="flex flex-row">
          <section className="flex flex-col mt-2 justify-center mx-2">
            <label>Estanque</label>
            <select
              className="m-0 h-12"
              name="role"
              value={bassin}
              onChange={(e) => setBassin(e.target.value)}
            >
              <option key={201} value={"Todos"}>
                Todos
              </option>
              {bassinsList.map((Bassin, key) => (
                <option key={key} value={Bassin[1]}>
                  {Bassin[1]}
                </option>
              ))}
            </select>
          </section>
          <section className="flex flex-col mt-2 justify-center mx-2">
            <label>Fecha inicio</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha"
              value={dates.first}
              onChange={(e) =>
                setDates({
                  ...dates,
                  first: e.target.value,
                })
              }
            />
          </section>
          <section className="flex flex-col mt-2 justify-center">
            <label>Fecha fin</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha"
              value={dates.second}
              onChange={(e) =>
                setDates({
                  ...dates,
                  second: e.target.value,
                })
              }
            />
          </section>
        </div>
      </div>
      {list.length < 0 ? null : (
        <TableAeration
          list={list}
          setList={setList}
          bassinsList={bassinsList}
          aerateursList={aerateursList}
          maj={maj}
          setMaj={setMaj}
        />
      )}
    </div>
  );
}
