import RowEditable from "components/RowEditable";
import { authFetch } from "auth";
import { toast } from "react-toastify";
import { v1 } from "uuid";
import CloseCycleDialog from "./CloseCycleDialog";
import { useState } from "react";

export default function CyclesTable({
  list,
  headers,
  maj,
  setMaj,
  handleClick,
}) {
  const [open, setOpen] = useState(false);
  const [cycleToClose, setCycleToClose] = useState("");

  const handleEdit = (edited, id) => {
    const data = {
      id: id,
      Estanque: edited[0].value,
      FechaLleno: edited[1].value,
      Superficie: edited[6].value,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    authFetch("/api/cycles", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setMaj(~maj);
  };

  const handleEndCycle = (id) => {
    setCycleToClose(id);
    setOpen(true);
  };

  return (
    <>
      <CloseCycleDialog
        maj={maj}
        setMaj={setMaj}
        cycle={cycleToClose}
        open={open}
        setOpen={setOpen}
      />
      <table width="100%" className="min-w-full">
        <thead className="bg-teal-500 rounded">
          <tr>
            {headers.map((h, key) => {
              return (
                <th
                  key={key}
                  className="text-sm font-medium text-white px-1 py-4 text-left w-6"
                >
                  {h}
                </th>
              );
            })}
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(
            (
              {
                id,
                Estanque,
                FechaLleno,
                FechaUltimaPesca,
                meses,
                Alimentacion,
                AlimentacionThéo,
                Superficie,
                Especie,
                PesoSembrado,
                PesoPescado,
                PesoActual,
                Mortalidad,
                Rendimiento,
                AlimentacionTotal,
                IndiceConversion,
              },
              key
            ) => {
              const row = [
                {
                  name: "Estanque",
                  value: Estanque,
                  editable: false,
                  type: "input-text-short",
                },
                {
                  name: "Fecha lleno",
                  value: FechaLleno,
                  editable: false,
                  type: "input-date",
                },
                {
                  name: "Fecha ultima pesca",
                  value: FechaUltimaPesca,
                  editable: false,
                },
                {
                  name: "meses",
                  value: meses,
                  editable: false,
                },
                {
                  name: "Alimentacion",
                  value: Alimentacion,
                  editable: false,
                },
                {
                  name: "AlimentacionThéo",
                  value: AlimentacionThéo,
                  editable: false,
                },
                {
                  name: "Superficie",
                  value: Superficie,
                  editable: false,
                  type: "input-number",
                },
                {
                  name: "Especie",
                  value: Especie,
                  editable: false,
                },
                {
                  name: "PesoSembrado",
                  value: PesoSembrado,
                  editable: false,
                  type: "input-number",
                },
                {
                  name: "PesoPescado",
                  value: PesoPescado,
                  editable: false,
                },
                {
                  name: "PesoActual",
                  value: PesoActual,
                  editable: false,
                },
                {
                  name: "Mortalidad",
                  value: Mortalidad,
                  editable: false,
                },
                {
                  name: "Rendimiento",
                  value: Rendimiento,
                  editable: false,
                },
                {
                  name: "AlimentacionTotal",
                  value: AlimentacionTotal,
                  editable: false,
                },
                {
                  name: "IndiceConversion",
                  value: IndiceConversion,
                  editable: false,
                },
              ];
              return (
                <RowEditable
                  key={v1()}
                  id={id}
                  row={row}
                  handleClick={handleClick}
                  color={key % 2 === 1 ? "teal-50" : "white"}
                  handleEdit={handleEdit}
                  deleteRow={handleEndCycle}
                  removeEdit={true}
                />
              );
            }
          )}
        </tbody>
      </table>
    </>
  );
}
