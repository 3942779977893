import { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "../assets/css/App.css";
// creates a beautiful scrollbar
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import AncienCycle from "../views/AncienCycle";

import { useAuth, authFetch } from "../auth";

import logo from "assets/img/logo.png";
import Profile from "views/profile";
import { toast } from "react-toastify";

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const [logged] = useAuth();
  const [role, setRole] = useState(undefined);

  useEffect(() => {
    var url = "/api/user";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setRole(response.role);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, []);

  return roles && roles.includes(role) ? (
    <Route
      {...rest}
      render={(props) =>
        logged ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  ) : null;
};

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/finca") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            roles={prop.roles}
          />
        );
      }
      return null;
    })}
    <Route
      path="/finca/ancienCycle/:bassin/:dateDebut/:dateFin"
      component={AncienCycle}
    />
    <Route path="/profile" component={Profile} />
    <Redirect from="/finca" to="/finca/dashboard" />
  </Switch>
);

export default function Finca() {
  return (
    <div className="flex bg-slate-100 h-screen w-screen">
      <Sidebar routes={routes} logoText={"Finca"} logo={logo} />
      <div className="w-full mx-4 pt-4 max-h-screen overflow-auto">
        {switchRoutes}
      </div>
    </div>
  );
}
