import React, { useState } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function AddAlimentDialog({ setMaj, maj }) {
  const [open, setOpen] = useState(false);
  const [newAliment, setNewAliment] = useState({
    libelle: "",
    price: "",
    weight: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/aliments";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...newAliment,
      }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setOpen(false);
    setNewAliment({
      libelle: "",
      price: "",
      weight: "",
    });
  };

  return (
    <div>
      <button
        className="btn-teal px-4"
        type="button"
        onClick={() => setOpen(true)}
      >
        Añadir un tipo de alimento
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2> Añadir un tipo de alimento</h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <form
                  className="mt-5"
                  onSubmit={handleSubmit}
                  id="changeAliment"
                >
                  <div className="md:grid grid-cols-3 gap-2 relative p-6">
                    <section className="flex flex-col">
                      <label>Nombre</label>
                      <input
                        type="text"
                        name="name"
                        value={newAliment.libelle}
                        onChange={(e) =>
                          setNewAliment({
                            ...newAliment,
                            libelle: e.target.value,
                          })
                        }
                        placeholder="Nombre"
                      />
                    </section>
                    <section className="flex flex-col">
                      <label>Precio por saco</label>
                      <input
                        type="number"
                        name="name"
                        value={newAliment.price}
                        onChange={(e) =>
                          setNewAliment({
                            ...newAliment,
                            price: e.target.value,
                          })
                        }
                        placeholder="Precio"
                      />
                    </section>
                    <section className="flex flex-col">
                      <label>Peso de un saco</label>
                      <input
                        type="number"
                        name="name"
                        value={newAliment.weight}
                        onChange={(e) =>
                          setNewAliment({
                            ...newAliment,
                            weight: e.target.value,
                          })
                        }
                        placeholder="Peso"
                      />
                    </section>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="btn-red-transparent"
                      type="button"
                      onClick={() => setOpen(false)}
                    >
                      Cerrar
                    </button>
                    <button className="btn-teal" type="submit">
                      Añadir
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
