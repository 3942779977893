import { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";
import ShowChangementEau from "components/ChangementEau/ShowChangementEau";

export default function FormDialog({ bassin, maj, setMaj }) {
  const [open, setOpen] = useState(false);
  const [bassinsList, setBassinsList] = useState([""]);
  const [pompesList, setPompesList] = useState([""]);

  useEffect(() => {
    var url = "/api/pompes/";
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setBassinsList(response.bassins.sort((a, b) => (a[1] > b[1] ? 1 : -1)));
        setPompesList(response.pompes.sort((a, b) => (a[1] > b[1] ? 1 : -1)));
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, []);

  return (
    <div className="mx-2">
      <button className="btn-teal" type="button" onClick={() => setOpen(true)}>
        Ver cambio de agua
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto   w-2/3">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="overflow-scroll max-h-4/5">
                  <ShowChangementEau
                    defaultBassin={bassin}
                    bassinsList={bassinsList}
                    pompesList={pompesList}
                    maj={maj}
                    setMaj={setMaj}
                  />
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="btn-red-transparent"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
