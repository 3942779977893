import React, { useState } from "react";

export default function SetDate({ date, handleDateChange }) {
  const [open, setOpen] = useState(false);
  const [newDate, setNewDate] = useState(date);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleDateChange(newDate);
    setOpen(false);
  };

  return (
    <div className="mx-2">
      <button className="btn-teal" type="button" onClick={() => setOpen(true)}>
        {date}
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2>Cambiar fecha</h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <form
                  className="mt-5"
                  onSubmit={handleSubmit}
                  id="changeAliment"
                >
                  <div className="md:grid grid-cols-1 gap-2 relative p-6 ">
                    <section className="flex flex-col">
                      <label>Fecha</label>
                      <input
                        className="m-0"
                        type="date"
                        placeholder="Fecha"
                        value={newDate}
                        onChange={(e) => setNewDate(e.target.value)}
                      />
                    </section>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="btn-red-transparent"
                      type="button"
                      onClick={() => setOpen(false)}
                    >
                      Cerrar
                    </button>
                    <button className="btn-teal" type="submit">
                      Confirmar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
