import { useState, useEffect, useRef } from "react";
import { authFetch } from "auth";
import ReactToPrint from "react-to-print";
import Header from "components/Factures/Print/Header";
import Table from "components/Factures/Print/Table";
import Article from "components/Factures/Article";
import Total from "components/Factures/Print/Total";
import { toast } from "react-toastify";

export default function CreateInvoice({
  clientsList,
  typeAlimentList,
  maj,
  setMaj,
}) {
  const [client, setClient] = useState({
    name: clientsList[0][1],
    adress: clientsList[0][2],
    id: clientsList[0][0],
    cedula: clientsList[0][3],
    phone: clientsList[0][4],
  });
  const [list, setList] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [total, setTotal] = useState(0);

  const componentRef = useRef();

  useEffect(() => {
    var sum = 0;
    for (let i = 0; i < list.length; i++) {
      const e = list[i];
      sum += e.quantity * e.price * (1 - e.discount / 100);
    }
    setTotal(sum);
  }, [list]);

  const handleClientChange = (id) => {
    const l = clientsList.filter((c) => c[0] === parseInt(id))[0];
    const c = {
      name: l[1],
      adress: l[2] ? l[2] : "",
      id: l[0],
      cedula: l[3] ? l[3] : "",
      phone: l[4] ? l[4] : "",
    };
    setClient(c);
  };

  const handleConfirm = () => {
    var url = "/api/ventes/aliments";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ list: list, date: date, client: client }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
        setList([]);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  return (
    <>
      <section className="mt-4">
        <div className="flex flex-col justify-center ">
          <article className="flex flex-row justify-center  w-full">
            <div className="flex flex-col max-w-xs self-center">
              <label htmlFor="name">Cliente</label>
              <select
                id="client"
                value={client.id}
                onChange={(e) => handleClientChange(e.target.value)}
              >
                {clientsList.map((c, key) => (
                  <option key={key} value={c[0]}>
                    {c[1]}
                  </option>
                ))}
              </select>
            </div>
          </article>
          <hr className="mt-2 mb-2" />
          <article className="md:grid grid-cols-5 gap-10">
            <div className="flex flex-col">
              <label htmlFor="name">Nombre</label>
              <input
                type="text"
                name="text"
                id="name"
                placeholder="Nombre"
                autoComplete="off"
                value={client.name}
                onChange={(e) => setClient({ ...client, name: e.target.value })}
              />
            </div>

            <div className="flex flex-col">
              <label htmlFor="address">Direccion</label>
              <input
                type="text"
                name="address"
                id="address"
                placeholder="Direccion del cliente"
                autoComplete="off"
                value={client.adress}
                onChange={(e) =>
                  setClient({ ...client, adress: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="Cedula">Cedula</label>
              <input
                type="text"
                name="Cedula"
                id="Cedula"
                placeholder="Cedula"
                autoComplete="off"
                value={client.cedula}
                onChange={(e) =>
                  setClient({ ...client, cedula: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="phone">Numero de telefono</label>
              <input
                type="text"
                name="phone"
                id="phone"
                placeholder="Numero de telefono"
                autoComplete="off"
                value={client.phone}
                onChange={(e) =>
                  setClient({ ...client, phone: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="invoiceDate">Fecha</label>
              <input
                className="border-teal-500"
                type="date"
                name="invoiceDate"
                id="invoiceDate"
                placeholder="Fecha"
                autoComplete="off"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
          </article>

          {/* This is our table form */}
          <article>
            {typeAlimentList.length > 1 ? (
              <Article
                list={list}
                setList={setList}
                itemsList={typeAlimentList}
                maj={maj}
                setMaj={setMaj}
              />
            ) : null}
          </article>
        </div>
      </section>
      {/* Invoice Preview */}
      <div
        hidden={list.length === 0}
        className="invoice__preview bg-white p-5 rounded"
      >
        <div className="flex flex-col w-full items-center">
          <ReactToPrint
            trigger={() => <button className="btn-blue">Imprimir</button>}
            content={() => componentRef.current}
          />
          <button onClick={handleConfirm} className=" btn-teal">
            Confirmar
          </button>
        </div>
        <div ref={componentRef} className="p-5 divide-y-2">
          <div>
            <Header
              name={client.name}
              adress={client.adress}
              cedula={client.cedula}
              phone={client.phone}
              date={
                date.split("-")[2] +
                "/" +
                date.split("-")[1] +
                "/" +
                date.split("-")[0]
              }
            />
            <Table list={list} setList={setList} />
            <Total total={total} />
          </div>
          <div>
            <Header
              name={client.name}
              adress={client.adress}
              cedula={client.cedula}
              phone={client.phone}
              date={
                date.split("-")[2] +
                "/" +
                date.split("-")[1] +
                "/" +
                date.split("-")[0]
              }
            />
            <Table list={list} setList={setList} />
            <Total total={total} />
          </div>
        </div>
      </div>
    </>
  );
}
