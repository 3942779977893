export default function Header({ name, adress, cedula, phone, date }) {
  return (
    <>
      <header className="flex flex-col items-start mb-5 mt-5 ">
        <div>
          <h1 className="font-bold uppercase tracking-wide text-4xl mb-3">
            Factura
          </h1>
        </div>
        <div className="flex flex-row justify-between w-full">
          <section className="mt-5 grid grid-cols-2 gap-2 ">
            <p>Nombre :</p>
            <p className="font-bold">{name}</p>
            <p>Direccion :</p>
            <p>{adress}</p>
            <p>Cedula :</p>
            <p className="min-w">{cedula}</p>
            <p>Telefono :</p>
            <p>{phone}</p>
          </section>
          <article className="flex items-start justify-end">
            <ul>
              <li className="p-1 bg-gray-100">
                <span className="font-bold text-2xl">Date: {date}</span>
              </li>
            </ul>
          </article>
        </div>
      </header>
    </>
  );
}
