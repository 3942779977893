import React, { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";

export default function ChangeAliment({ item, setMaj, maj, open, setOpen }) {
  const [aliment, setAliment] = useState({
    id: item[0],
    name: item[1],
    weight: item[2],
    price: item[3],
  });

  useEffect(() => {
    setAliment({
      id: item[0],
      name: item[1],
      weight: item[2],
      price: item[3],
    });
  }, [item]);

  const handleSubmit = (e) => {
    e.preventDefault();
    var url = "/api/aliments";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(aliment),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
    setOpen(false);
  };

  return (
    <div>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b-2 border-solid border-slate-200 rounded-t">
                  <h2>
                    Cambiar alimento <b>{aliment.name}</b>
                  </h2>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-80 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="bg-transparent text-black opacity-80 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                <form
                  className="mt-5"
                  onSubmit={handleSubmit}
                  id="changeAliment"
                >
                  <div className="md:grid grid-cols-3 gap-6  mx-5">
                    <div className="flex flex-col">
                      <label>Nombre</label>
                      <input
                        type="text"
                        name="Nombre"
                        id="Nombre"
                        placeholder="Nombre"
                        value={aliment.name}
                        onChange={(e) =>
                          setAliment({ ...aliment, name: e.target.value })
                        }
                      />
                    </div>

                    <div className="flex flex-col">
                      <label>Peso por saco</label>
                      <input
                        type="number"
                        name="weightPerUnit"
                        id="weightPerUnit"
                        placeholder="Peso por saco"
                        value={aliment.weight}
                        onChange={(e) =>
                          setAliment({ ...aliment, weight: e.target.value })
                        }
                      />
                    </div>

                    <div className="flex flex-col">
                      <label>Precio por saco</label>
                      <input
                        type="number"
                        name="pricePerUnit"
                        id="pricePerUnit"
                        placeholder="Precio"
                        value={aliment.price}
                        onChange={(e) =>
                          setAliment({ ...aliment, price: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  {/*footer*/}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                      onClick={() => setOpen(false)}
                      className="btn-red-transparent"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn-teal">
                      Cambiar alimento
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
