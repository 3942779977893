import React, { useState } from "react";
import VentesParJour from "../VentesParJour";

export default function ShowVentes({
  setMaj,
  maj,
  typeAlimentList,
  defaultDate,
  defaultFiltre,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button
        className="btn-blue px-4"
        type="button"
        onClick={() => setOpen(true)}
      >
        Ver ventas de alimento
      </button>
      {open ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto ">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-5">
                <VentesParJour
                  maj={maj}
                  setMaj={setMaj}
                  typeAlimentList={typeAlimentList}
                  defaultDate={defaultDate}
                  defaultFiltre={defaultFiltre}
                />
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="btn-red-transparent"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </div>
  );
}
