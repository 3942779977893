import { useState, useEffect } from "react";
import { authFetch } from "auth";
import { toast } from "react-toastify";
import RowEditable from "./RowEditable";

export default function VentesParJour({
  maj,
  setMaj,
  typeAlimentList,
  defaultDate,
  defaultFiltre,
}) {
  const [list, setList] = useState([]);
  const [date, setDate] = useState(
    defaultDate ? defaultDate : new Date().toISOString().substring(0, 10)
  );
  const [filtre, setFiltre] = useState(defaultFiltre ? defaultFiltre : "all");

  useEffect(() => {
    const url = "/api/ventesParJour/" + date + "/" + filtre;
    authFetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        setList(response.ventes);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  }, [date, filtre, maj]);

  const handleNewRow = (row, id) => {
    const i = list.findIndex((r) => r.id === id);
    var temp = [...list];
    temp[i] = {
      id: id,
      client: row[0].value,
      quantity: row[1].value,
      typeAliment: row[2].value,
      comment: row[3].value,
    };
    setList(temp);
    var url = "/api/ventesParJour";
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id, comment: row[3].value }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  // Delete function
  const deleteRow = (id) => {
    var url = "/api/clients";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: id }),
    };
    authFetch(url, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json().then((data) => {
            throw new Error(data.message);
          });
        }
      })
      .then((response) => {
        toast.success(response.message);
        setMaj(~maj);
      })
      .catch((e) => toast.error(e.name + ": " + e.message));
  };

  return (
    <div>
      <div className="flex justify-between border-b-2 mb-4">
        <h2 className="my-5">Ver las ventas por dias</h2>
        <div className="flex flex-row">
          <section className="flex flex-col mt-2 mr-5 justify-center">
            <label>Alimento</label>
            <select
              className="m-0"
              name="role"
              value={filtre}
              onChange={(e) => setFiltre(e.target.value)}
              placeholder="Alimento"
            >
              {"all"}
              <option key={0} value={"all"}>
                Todos
              </option>
              {typeAlimentList.map((a, key) => {
                return (
                  <option key={key} value={a[0]}>
                    {a[1]}
                  </option>
                );
              })}
            </select>
          </section>
          <section className="flex flex-col mt-2 justify-center">
            <label>Fecha</label>
            <input
              className="m-0"
              type="date"
              placeholder="Fecha"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </section>
        </div>
      </div>
      <table width="100%" className="min-w-full ">
        <thead className="bg-teal-500 rounded">
          <tr>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Cliente
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Cantidad
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Typo de alimento
            </th>
            <th className="text-sm font-medium text-white px-6 py-4 text-left">
              Comentario
            </th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {list.map(({ id, client, quantity, typeAliment, comment }, key) => {
            const row = [
              {
                name: "client",
                value: client,
                editable: false,
              },
              {
                name: "quantity",
                value: quantity,
                editable: false,
              },
              {
                name: "typeAliment",
                value: typeAliment,
                editable: false,
              },
              {
                name: "comment",
                value: comment,
                editable: true,
                type: "input-text",
              },
            ];
            return (
              <RowEditable
                id={id}
                key={key}
                row={row}
                color={key % 2 === 1 ? "teal-50" : "white"}
                handleEdit={handleNewRow}
                deleteRow={deleteRow}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
